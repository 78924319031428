import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Space, Table, Tooltip } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import settings from '../../../settings';
import { FinanceReportTypes } from './constants';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';

function FinanceReportsTable({ financeReports, loading }) {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });
  const handleTableChange = useCallback((params) => {
    setPagination({
      ...params.pagination,
    });
  }, []);
  const locale = useSelector((store) => store.locale, shallowEqual);
  const dateFormat = useMemo(
    () =>
      new Intl.DateTimeFormat(locale, {
        dateStyle: 'full',
      }),
    [locale],
  );
  return (
    <Table
      dataSource={financeReports}
      loading={!financeReports.length && loading}
      pagination={{ ...pagination, total: financeReports.length }}
      onChange={handleTableChange}
      style={{ width: '100%' }}
      rowKey="_id"
      scroll={{ x: 800 }}
    >
      <Table.Column
        title="Description"
        dataIndex="desc"
        key="desc"
        width={240}
      />
      <Table.Column
        title="Type"
        dataIndex="type"
        render={(text, record) => FinanceReportTypes[record.type]?.label}
        width={160}
      />
      <Table.Column
        title="Details"
        dataIndex="details"
        render={(text, record) => {
          if (record.type === FinanceReportTypes.BALANCES.key) {
            return `Total: ${displayMoney(
              getNumberValue(record.total) / 10000,
            )}`;
          }
          return null;
        }}
        width={160}
      />
      <Table.Column
        title="Created"
        dataIndex="createdAt"
        render={(text, record) => {
          if (record.balance) {
            return dateFormat.format(new Date(record.createdAt));
          }
          return dateFormat.format(new Date(record.createdAt));
        }}
      />
      <Table.Column
        title="Action"
        key="action"
        render={(text, record) => (
          <Space size="middle">
            <Tooltip title="View">
              <Link to={`/accounting/reports/view/${record._id}`}>
                <Button icon={<EyeOutlined />} />
              </Link>
            </Tooltip>
            {record.file && (
              <Tooltip title="Download CSV">
                <Button
                  icon={<DownloadOutlined />}
                  target="_blank"
                  download={record?.file.filename}
                  href={record?.file.url}
                  style={{ marginLeft: -8, marginBottom: 0 }}
                />
              </Tooltip>
            )}
          </Space>
        )}
      />
    </Table>
  );
}

export default FinanceReportsTable;
