import { getPgDisplayName } from '@aims/shared/people-groups/utils';
import { getQuarterLabel } from '@aims/shared/reports/quarters';
import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';
import {
  DeleteOutlined,
  DownloadOutlined,
  MoreOutlined,
  ReloadOutlined,
  SearchOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Button,
  Dropdown,
  Form,
  Input,
  Menu,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
  notification,
} from 'antd';
import moment from 'moment';
import React, { useCallback, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import settings from '../../settings';
import DeleteAdoptionStatsModal from './DeleteAdoptionStatsModal';
import SelectEmailStatus from './SelectEmailStatus';
import SelectQuarter from './SelectQuarter';
import {
  AdopterEmailStatuses,
  allUpgStatsRecordsForAdminQuery,
  extractAllUpgStatsRecordsForAdmin,
  sendAdoptionEmailMutation,
} from './constants';

const { Title, Text } = Typography;

function ListAdoptionStats({ defaultFilters }) {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: settings.pageSize,
  });

  const handleTableChange = useCallback((params) => {
    setPagination({
      ...params.pagination,
    });
  }, []);

  const filters = useRef(defaultFilters);

  const [sortedBy, setSortedBy] = useState('quarter.keyword');
  const [sortOrder, setSortOrder] = useState('DESC');
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);

  const {
    error,
    loading,
    data: adoptionStats,
    search: adoptionStatsSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allUpgStatsRecordsForAdminQuery,
    extract: extractAllUpgStatsRecordsForAdmin,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId: 'listAdoptionStats',
  });

  const handleLoadMore = useCallback(() => {
    next();
  }, [next]);

  const [deleting, setDeleting] = useState();
  const handleDelete = useCallback((_adoptionStatsRow) => {
    setDeleting({ adoptionStatsRow: _adoptionStatsRow });
  }, []);
  const onCancelDelete = useCallback(() => {
    setDeleting(undefined);
  }, []);
  const onDeleteFinish = useCallback(() => {
    setDeleting(undefined);
    refetch();
  }, [refetch]);

  const [sending, setSending] = useState({});
  const [sendAdoptionEmail] = useMutation(sendAdoptionEmailMutation);
  const handleSendEmail = useCallback(
    async (_id) => {
      setSending({ ...sending, [_id]: true });
      try {
        await sendAdoptionEmail({
          variables: {
            _id,
          },
        });
        notification.success({
          message: 'Scheduled',
          description: 'Sponsor email scheduled successfully',
        });
        refetch();
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error scheduling sponsor email',
        });
      }
      setSending({ ...sending, [_id]: false });
    },
    [sendAdoptionEmail, refetch, sending],
  );

  const [form] = Form.useForm();
  const onFiltersChanged = useCallback(
    (_changed) => {
      const { search, ...changed } = _changed;
      if (search != undefined) {
        adoptionStatsSearch(search);
      } else {
        filters.current = {
          ...filters.current,
          ...changed,
        };
        refetch();
      }
    },
    [adoptionStatsSearch, refetch],
  );

  const thisYear = new Date().getFullYear();
  const years = [];
  for (let i = -2; i < 1; i += 1) {
    years.push(thisYear + i);
  }
  const quarters = [];
  years.forEach((year) => {
    ['Q1', 'Q2', 'Q3', 'Q4'].forEach((qx) => {
      quarters.push(`${year}.${qx}`);
    });
  });

  const headers = [
    { label: 'People Group Id', key: 'peopleGroupId' },
    { label: 'Region Code', key: 'regionCode' },
    { label: 'People Group', key: 'nameAcrossCountries' },
    { label: 'Country', key: 'country' },
    { label: 'Quarter', key: 'quarter' },
    { label: 'Holistic Projects', key: 'numHolisticProject' },
    { label: 'Active Sponsorships', key: 'numActiveAdoptions' },
    { label: 'Sponsors', key: 'numAdopters' },
    { label: 'Regional Coordinators', key: 'numRegionalCoords' },
    { label: 'Field Workers', key: 'numFieldWorkers' },
    { label: 'Churches Planted', key: 'numChurchesPlanted' },
    { label: 'First Time Hearers', key: 'numHeardForFirstTime' },
    { label: 'Salvations', key: 'numSalvations' },
    { label: 'Water Baptisms', key: 'numWaterBaptisms' },
    { label: 'Miracles', key: 'numMiracles' },
    { label: 'Prayer Requests', key: 'numPrayerRequests' },
    { label: 'To Field Workers', key: 'totalForFieldWorker' },
    { label: 'To Holistic Projects', key: 'totalForHolistic' },
    { label: 'Total Received', key: 'totalReceived' },
    { label: 'Sponsor Email', key: 'adopterEmailStatus' },
    { label: 'Updated At', key: 'updatedAt' },
  ];

  const formItemStyle = { marginRight: 16 };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button icon={<ReloadOutlined />} onClick={refetch} htmlType="button">
          Refresh
        </Button>
      </div>
      <Form
        layout="vertical"
        form={form}
        onValuesChange={onFiltersChanged}
        style={{ display: 'flex', flexWrap: 'wrap' }}
      >
        {!defaultFilters?.peopleGroupId && (
          <Form.Item name="search" style={formItemStyle} label="Search">
            <Input
              placeholder="Search"
              suffix={<SearchOutlined />}
              style={{ maxWidth: 500, minWidth: 300 }}
            />
          </Form.Item>
        )}
        <SelectQuarter
          loading={loading}
          required={false}
          selectAttrs={{ allowClear: true }}
          formItemAttrs={{
            style: { ...formItemStyle, minWidth: 200 },
            label: 'Single Quarter',
          }}
        />
        <Form.Item
          name="quarterAtOrAfter"
          label="From Quarter"
          style={formItemStyle}
        >
          <Select
            style={{ minWidth: 200 }}
            placeholder="From Quarter"
            allowClear="true"
          >
            {quarters.map((quarter) => (
              <Select.Option key={quarter}>{quarter}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="quarterBefore"
          label="To Quarter"
          style={formItemStyle}
        >
          <Select
            style={{ minWidth: 200 }}
            placeholder="To Quarter"
            allowClear="true"
          >
            {quarters.map((quarter) => (
              <Select.Option key={quarter}>{quarter}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <SelectEmailStatus
          loading={loading}
          selectAttrs={{ allowClear: true }}
          formItemAttrs={{ style: formItemStyle, label: 'Email Status' }}
        />
      </Form>
      <div style={{ display: 'flex', justifyContent: 'flex-end', margin: 16 }}>
        <Text style={{ marginRight: '16px' }}>
          Total: {adoptionStats.length}
        </Text>
        <CSVLink
          data={adoptionStats}
          headers={headers}
          filename={`adoption_stats(${moment(new Date()).format(
            'MM-DD-YYYY',
          )})`}
        >
          <DownloadOutlined style={{ marginRight: '8px' }} />
          Export CSV
        </CSVLink>
      </div>
      <Table
        dataSource={adoptionStats}
        loading={!adoptionStats.length && loading}
        pagination={{ ...pagination, total: adoptionStats.length }}
        onChange={handleTableChange}
        style={{ width: '100%' }}
        rowKey="_id"
        scroll={{ x: true }}
        bordered
      >
        {!defaultFilters?.peopleGroupId && (
          <Table.Column
            title="People Group"
            key="peopleGroup"
            render={(text, record) => {
              return (
                <Link
                  to={`/people-group/view/${record.peopleGroupId}/info`}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {getPgDisplayName(record)}
                </Link>
              );
            }}
          />
        )}
        <Table.Column
          title="Quarter"
          key="quarter"
          render={(text, record) => {
            return (
              <Text style={{ whiteSpace: 'nowrap' }}>
                {getQuarterLabel(record.quarter)}
              </Text>
            );
          }}
        />
        <Table.Column
          title="Holistic Projects"
          dataIndex="numHolisticProject"
          key="numHolisticProject"
        />
        <Table.Column
          title="Active Sponsorships"
          dataIndex="numActiveAdoptions"
          key="numActiveAdoptions"
        />
        <Table.Column
          title="Field Workers"
          dataIndex="numFieldWorkers"
          key="numFieldWorkers"
        />
        <Table.Column
          title="Churches Planted"
          dataIndex="numChurchesPlanted"
          key="numChurchesPlanted"
        />
        <Table.Column
          title="First Time Hearers"
          dataIndex="numHeardForFirstTime"
          key="numHeardForFirstTime"
        />
        <Table.Column
          title="Salvations"
          dataIndex="numSalvations"
          key="numSalvations"
        />
        <Table.Column
          title="Water Baptisms"
          dataIndex="numWaterBaptisms"
          key="numWaterBaptisms"
        />
        <Table.Column
          title="Testimonies"
          dataIndex="numMiracles"
          key="numMiracles"
        />
        <Table.Column
          title="Prayer Requests"
          dataIndex="numPrayerRequests"
          key="numPrayerRequests"
        />
        <Table.Column
          title="To Field Workers"
          key="totalForFieldWorker"
          render={(text, record) => {
            return displayMoney(getNumberValue(record.totalForFieldWorker));
          }}
        />
        <Table.Column
          title="To Holistic Projects"
          key="totalForHolistic"
          render={(text, record) => {
            return displayMoney(getNumberValue(record.totalForHolistic));
          }}
        />
        <Table.Column
          title="Sponsor Email"
          key="adopterEmailStatus"
          render={(text, record) => {
            let status;
            if (record.adopterEmailStatus) {
              status =
                AdopterEmailStatuses[record.adopterEmailStatus] &&
                AdopterEmailStatuses[record.adopterEmailStatus].label;
            } else {
              status = AdopterEmailStatuses.NOT_SENT.label;
            }
            return <Text style={{ whiteSpace: 'nowrap' }}>{status}</Text>;
          }}
        />
        <Table.Column
          title="Action"
          key="action"
          fixed={'right'}
          render={(text, record) => (
            <Space size="middle">
              <Tooltip title="Send Sponsor Email">
                <Button
                  onClick={() => handleSendEmail(record._id)}
                  icon={<SendOutlined />}
                  loading={sending[record._id]}
                />
              </Tooltip>
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item
                      danger
                      icon={<DeleteOutlined />}
                      onClick={() => handleDelete(record)}
                    >
                      Delete Row
                    </Menu.Item>
                  </Menu>
                }
                trigger="click"
              >
                <Button icon={<MoreOutlined />} />
              </Dropdown>
            </Space>
          )}
        />
      </Table>
      <div>
        {hasNextPage && <Button onClick={handleLoadMore}>Load More</Button>}
      </div>
      <DeleteAdoptionStatsModal
        deleting={deleting}
        onCancel={onCancelDelete}
        onFinish={onDeleteFinish}
      />
    </>
  );
}

export default ListAdoptionStats;
