import {
  CloseCircleOutlined,
  CoffeeOutlined,
  DashboardOutlined,
  FireOutlined,
  PauseCircleOutlined,
  ProjectOutlined,
  RocketOutlined,
  StarOutlined,
  ThunderboltOutlined,
} from '@ant-design/icons';
import { gql } from '@apollo/client';
import React from 'react';

export const HolisticProjectTypes = {
  AGRICULTURE: {
    key: 'AGRICULTURE',
    label: 'Agriculture',
  },
  SMALL_BUSINESS: {
    key: 'SMALL_BUSINESS',
    label: 'Small Business',
  },
  EDUCATION: {
    key: 'EDUCATION',
    label: 'Education',
  },
  MANUFACTURING: {
    key: 'MANUFACTURING',
    label: 'Manufacturing',
  },
  BUILDING: {
    key: 'BUILDING',
    label: 'Building',
  },
  TRANSLATION: {
    key: 'TRANSLATION',
    label: 'Translation',
  },
  CLEAN_WATER: {
    key: 'CLEAN_WATER',
    label: 'Clean Water',
  },
  MEDIA: {
    key: 'MEDIA',
    label: 'Media',
  },
  HEALTH_CARE: {
    key: 'HEALTH_CARE',
    label: 'Health Care',
  },
  HUMANITARIAN: {
    key: 'HUMANITARIAN',
    label: 'Humanitarian',
  },
  OTHER: {
    key: 'OTHER',
    label: 'Other',
  },
};

export const HolisticProjectStates = {
  PLANNING: {
    key: 'PLANNING',
    label: 'Planning',
  },
  DEVELOPMENT: {
    key: 'DEVELOPMENT',
    label: 'Developement',
  },
  ACTIVE: {
    key: 'ACTIVE',
    label: 'Active',
  },
  COMPLETE: {
    key: 'COMPLETE',
    label: 'Complete',
  },
};

export const HolisticProjectStages = {
  PROPOSED: {
    key: 'PROPOSED',
    label: 'Proposed',
  },
  PREPARING: {
    key: 'PREPARING',
    label: 'Preparing',
    // icon: <OrderedListOutlined />,
    icon: <CoffeeOutlined />,
  },
  APPROVED: {
    key: 'APPROVED',
    label: 'Approved',
  },
  FUNDING: {
    key: 'FUNDING',
    label: 'Funding',
    icon: <RocketOutlined />,
  },
  FUNDED: {
    key: 'FUNDED',
    label: 'Funded',
    icon: <FireOutlined />,
  },
  ACTIVE: {
    key: 'ACTIVE',
    label: 'Active',
    icon: <ThunderboltOutlined />,
  },
  COMPLETE: {
    key: 'COMPLETE',
    label: 'Complete',
    icon: <StarOutlined />,
  },
  FAILED: {
    key: 'FAILED',
    label: 'Failed',
    icon: <CloseCircleOutlined />,
  },
  ON_HOLD: {
    key: 'ON_HOLD',
    label: 'On Hold',
    icon: <PauseCircleOutlined />,
  },
};

export const holisticProjectFullAttributes = gql`
  {
    _id
    applicationId
    peopleGroupIds
    peopleGroups {
      _id
      nameAcrossCountries
      country
    }
    projectName
    typeOfProject
    desc
    status
    tags {
      key
      color
    }
    photoIds
    primaryThumbnailUrl
    photos {
      _id
      s3Key
      size
      filename
      contentType
      url
      thumbnail {
        url
      }
      images {
        sizeCode
        url
      }
    }
    primaryPhotoId
    numSupported
    canCreateReports
    createdBy
    updatedBy
    deletedBy
    createdAt
    updatedAt
    deletedAt
  }
`;

export const holisticProjectShortAttributes = gql`
  {
    _id
    peopleGroupIds
    peopleGroups {
      _id
      nameAcrossCountries
      country
    }
    projectName
    typeOfProject
    desc
    status
    primaryThumbnailUrl
    tags {
      key
      color
    }
    createdBy
    updatedBy
    deletedBy
    createdAt
    updatedAt
    deletedAt
  }
`;

export const holisticProjectPriviledgedShortAttributes = gql`
  {
    _id
    applicationId
    regionalCoordId
    regionalCoordinator {
      _id
      fullName
    }
    peopleGroupIds
    peopleGroups {
      _id
      nameAcrossCountries
      country
    }
    projectName
    typeOfProject
    desc
    status
    loanTotalX4
    primaryThumbnailUrl
    tags {
      key
      color
    }
    createdBy
    updatedBy
    deletedBy
    createdAt
    updatedAt
    deletedAt
  }
`;

export const holisticProjectPriviledgedAttributes = gql`
  {
    _id
    applicationId
    regionalCoordId
    regionalCoordinator {
      _id
      fullName
    }
    peopleGroupIds
    peopleGroups {
      _id
      nameAcrossCountries
      country
    }
    projectName
    typeOfProject
    desc
    status
    loanTotalX4
    loanTerm
    loanStartDate
    supportedFieldWorkers {
      contactId
      contact {
        _id
        fullName
        primaryThumbnailUrl
      }
      totalReceived
    }
    numSupported
    responsiblePersons {
      contactId
      contact {
        _id
        fullName
        primaryThumbnailUrl
      }
      role
    }
    tags {
      key
      color
    }
    photoIds
    photos {
      _id
      s3Key
      size
      filename
      contentType
      url
      thumbnail {
        url
      }
      images {
        sizeCode
        url
      }
    }
    primaryPhotoId
    cumulativeStats {
      numMiraclesReported
      hasReceivedFunds
      loanPaymentsMade
      cumulativeExpenses
      cumulativeRevenue
      supportedFieldWorkers {
        contactId
        totalReceived
      }
      latestExchangeRate
      latestCurrency
      numProgressReports
    }
    createdBy
    updatedBy
    deletedBy
    createdAt
    updatedAt
    deletedAt
  }
`;

export const holisticProjectQuery = gql`
  query HolisticProject($_id: ID!) {
    holisticProject(_id: $_id) ${holisticProjectFullAttributes}
  }
`;

export const holisticProjectPriviledgedQuery = gql`
  query HolisticProjectPriviledged($_id: ID!) {
    holisticProjectPriviledged(_id: $_id) ${holisticProjectPriviledgedAttributes}
  }
`;

export const allHolisticProjectsQuery = gql`
  query AllHolisticProjects(
    $first: Int
    $after: String
    $filters: HolisticProjectFiltersForAdmin
  ) {
    allHolisticProjects(first: $first, after: $after, filters: $filters) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${holisticProjectShortAttributes}
      }
    }
  }
`;

export const allHolisticProjectsPriviledgedQuery = gql`
  query AllHolisticProjectsPriviledged(
    $first: Int
    $after: String
    $filters: HolisticProjectFiltersForAdmin
  ) {
    allHolisticProjectsPriviledged(first: $first, after: $after, filters: $filters) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${holisticProjectPriviledgedShortAttributes}
      }
    }
  }
`;

export const extractAllHolisticProjectsForUser = (data) =>
  data.allHolisticProjectsForUser;
