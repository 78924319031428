import { useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import YesNoModal from '../../components/YesNoModal';
import {
  AdoptionLevels,
  triggerNewAdoptionEmailCampaignMutation,
} from './constants';

const { Text, Paragraph } = Typography;

function TriggerNewAdoptionEmailCampaignModal({ visible, onFinish, onCancel }) {
  const { adoption } = visible || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [triggerNewAdoptionEmailCampaign] = useMutation(
    triggerNewAdoptionEmailCampaignMutation,
  );

  const onYes = useCallback(async () => {
    setLoading(true);
    try {
      await triggerNewAdoptionEmailCampaign({
        variables: {
          adoptionId: adoption._id,
        },
      });
      notification.success({
        message: 'Success',
        description: '"New Sponsorship" email campaign started successfully',
      });
      onFinish();
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  }, [triggerNewAdoptionEmailCampaign, adoption, onFinish]);

  const onNo = useCallback(() => {
    if (!loading) {
      onCancel();
    }
  }, [onCancel, loading]);

  return (
    <YesNoModal
      title="New UPG Sponsorship Email Campaign"
      contents={
        <div>
          <div style={{ marginBottom: 8 }}>
            {adoption &&
              adoption.peopleGroup &&
              adoption.level &&
              AdoptionLevels[adoption.level] && (
                <>
                  <Text>{`${adoption.peopleGroup.nameAcrossCountries} / ${
                    adoption.peopleGroup.country
                  } - ${AdoptionLevels[adoption.level].label}`}</Text>
                  <br />
                </>
              )}
          </div>
          <div>
            <Paragraph>
              {
                'This will trigger a series of emails to be sent to the sponsor over the next few weeks.'
              }
            </Paragraph>
            <Paragraph>
              {'Would you like to launch this email campaign?'}
            </Paragraph>
          </div>
        </div>
      }
      yesText="Launch"
      noText="Cancel"
      onYes={onYes}
      onNo={onNo}
      visible={!!visible}
      loading={loading}
      error={error}
    />
  );
}

export default TriggerNewAdoptionEmailCampaignModal;
