import YesNoModalJ10 from '@aims/shared/components/YesNoModalJ10';
import { MailOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { Button, notification, Typography } from 'antd';
import React, { useCallback, useState } from 'react';

const { Text, Paragraph } = Typography;

const triggerNewProjectSponsorshipEmailCampaignMutation = gql`
  mutation TriggerNewProjectSponsorshipEmailCampaign($sponsorshipId: ID!) {
    triggerNewProjectSponsorshipEmailCampaign(sponsorshipId: $sponsorshipId)
  }
`;

function TriggerNewSponsorshipEmailCampaignModal({ sponsorship }) {
  const [visible, setVisible] = useState();

  const [loading, setLoading] = useState(false);
  const [launchCampaign] = useMutation(
    triggerNewProjectSponsorshipEmailCampaignMutation,
  );
  const onYes = useCallback(async () => {
    setLoading(true);
    try {
      await launchCampaign({
        variables: {
          sponsorshipId: sponsorship._id,
        },
      });
      notification.success({
        message: 'Success',
        description:
          '"New Project Partnership" email campaign started successfully',
      });
      setVisible(false);
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: 'We apologize.  An error occurred.',
      });
    }
    setLoading(false);
  }, [launchCampaign, sponsorship]);

  return (
    <>
      {!sponsorship?.campaignTriggeredAt && (
        <Button
          type="link"
          onClick={() => setVisible(true)}
          icon={<MailOutlined />}
        >
          Launch Email Campaign
        </Button>
      )}
      <YesNoModalJ10
        title="Launch Email Campaign"
        yesText="Launch"
        noText="Cancel"
        onYes={onYes}
        onNo={() => setVisible(false)}
        visible={visible}
        loading={loading}
      >
        <div>
          <div style={{ marginBottom: 8 }}>
            {sponsorship?.project?.projectName &&
              sponsorship?.contact?.fullName && (
                <>
                  <Text>{`${sponsorship?.project?.projectName}`}</Text>
                  <br />
                  <Text>{`${sponsorship?.contact?.fullName}`}</Text>
                </>
              )}
          </div>
          <div>
            <Paragraph>
              {
                'This will trigger a series of emails to be sent to the project partner over the next few weeks.'
              }
            </Paragraph>
            <Paragraph>
              {'Would you like to launch this email campaign?'}
            </Paragraph>
          </div>
        </div>
      </YesNoModalJ10>
    </>
  );
}

export default TriggerNewSponsorshipEmailCampaignModal;
