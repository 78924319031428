import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import { ArrowLeftOutlined, DownloadOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import { Button, Table, Tooltip, Typography } from 'antd';
import React, { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import { updateAccountAction } from '../../../redux-store/accounts-store';
import useQueryParams from '../../../shared/use-query-params';
import BalancesReportTotalsSection from './BalancesReportTotalsSection';
import { FinanceReportTypes } from './constants';
import settings from '../../../settings';
import sharedSettings from '@aims/shared/sharedSettings';
import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';
import { shallowEqual, useSelector } from 'react-redux';
import { AccountTypesJ25 } from '../accounts-j25/constants';

const { Title, Paragraph } = Typography;

const queryId = 'viewFinanceReportPage';

const queryAttributes = gql`
  {
    _id
    type
    desc
    fileId
    file {
      _id
      desc
      s3Key
      size
      filename
      contentType
      url
      uploaded
    }
    columnNames
    rows
    filters
    total
    totalIn
    totalOut
    totalX
    createdAt
    updatedAt
  }
`;

const financeReportAuery = gql`
  query FinanceReport($_id: ID!) {
    financeReportForAdmin(_id: $_id) ${queryAttributes}
  }
`;

const extract = (data) => data.financeReportForAdmin;

function BalancesReportTable({ report }) {
  const locale = useSelector((store) => store.locale, shallowEqual);
  const dateFormat = new Intl.DateTimeFormat(locale, {
    dateStyle: 'medium',
  });
  const rows = useMemo(() => {
    if (report) {
      return [...report.rows].sort((a, b) =>
        b?.updatedAt?.localeCompare(a?.updatedAt),
      );
    }
    return undefined;
  }, [report]);
  return (
    <Table
      dataSource={rows}
      pagination={false}
      style={{ width: '100%' }}
      rowKey="_id"
      scroll={{ x: 800 }}
    >
      <Table.Column title="Account" dataIndex="name" key="name" />
      <Table.Column title="Fund" dataIndex="fundName" />
      <Table.Column
        title="Account Type"
        dataIndex="accountType"
        render={(text, record) => {
          return AccountTypesJ25[record.accountType]?.label;
        }}
        width={160}
      />
      <Table.Column
        title="Balance"
        dataIndex="balance"
        render={(text, record) => {
          return displayMoney(getNumberValue(record.balanceX4) / 10000);
        }}
      />
      <Table.Column
        title="Updated"
        dataIndex="updatedAt"
        render={(text, record) => {
          return (
            record.updatedAt && dateFormat.format(new Date(record.updatedAt))
          );
        }}
        width={120}
      />
    </Table>
  );
}

function ViewFinanceReportPage() {
  const { _id } = useParams();
  const { loading, refetch } = useOneM1({
    variables: { _id },
    query: financeReportAuery,
    extract: extract,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: updateAccountAction,
    skip: !_id,
    showError: true,
  });
  const financeReport = useSingleSomethingA10('accounts', _id, queryId);

  const query = useQueryParams();
  const backLink = useMemo(() => {
    if (query && query.from) {
      return query.from;
    } else {
      return `/accounting/reports`;
    }
  }, [query]);

  return (
    <CSPage containerStyle={{ maxWidth: 'unset' }} title="View FinanceReport">
      <CSPageHeader
        titleComponent={
          <div>
            <Paragraph
              style={{
                marginBottom: 0,
                fontSize: 16,
                color: sharedSettings.colors.textGray,
              }}
            >
              Financial Report
            </Paragraph>
            <Title style={{ marginBottom: 0 }}>{financeReport?.desc}</Title>
            <Paragraph style={{ fontSize: 24, fontWeight: 600 }}>
              {FinanceReportTypes[financeReport?.type]?.label}
            </Paragraph>
          </div>
        }
        backActions={[
          <Link to={backLink} key="back">
            <Button type="text" icon={<ArrowLeftOutlined />}>
              Go Back
            </Button>
          </Link>,
        ]}
      />
      <BalancesReportTotalsSection report={financeReport} />
      <div style={{ height: 8 }} />
      <div style={{ display: 'flex', marginBottom: 16 }}>
        <div style={{ flex: 1 }} />
        <Tooltip title="Download CSV">
          <Button
            icon={<DownloadOutlined />}
            target="_blank"
            download={financeReport?.file.filename}
            href={financeReport?.file.url}
            style={{ marginLeft: -8, marginBottom: 0 }}
          >
            Export CSV
          </Button>
        </Tooltip>
      </div>
      {financeReport?.type === FinanceReportTypes.BALANCES.key && (
        <BalancesReportTable report={financeReport} />
      )}
    </CSPage>
  );
}

export default ViewFinanceReportPage;
