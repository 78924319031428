import React, { useEffect, useState } from 'react';
import {
  ExchangeRateInput,
  reportApprovedBy,
  ReportDescriptionAnswer,
  ReportLineItem,
  reportNotes,
  reportOnBehalfOf,
  reportOnBehalfOfName,
  reportPrivateFiles,
  reportPublicFiles,
  reportSubmittedBy,
} from '../common/ReportItems';
import {
  Form,
  Input,
  InputNumber,
  Button,
  Typography,
  List,
  Empty,
  Select,
  Table,
  Divider,
  Radio,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  displayMoney,
  getNumberValue,
  orderAndFilterFormList,
} from '../../shared/utils';
import { getPgDisplayName } from '../../people-groups/utils';
import { HolisticProjectTypes } from '../../holistic-projects/constants';
import ReportInfoItem from '../common/ReportInfoItem';
import SelectPeopleGroup from '../../people-groups/SelectPeopleGroup';
import CostAnalysisSummary from './CostAnalysisSummary';
import SelectFromMyPeopleGroups from '../../people-groups/SelectFromMyPeopleGroups';
import SelectFromMyFieldWorkers from '../../people-groups/SelectFromMyFieldWorkers';
import sharedSettings from '../../sharedSettings';
import { ReportApprovalStatuses } from '../constants';
import holisticPaymentPlan from './holistic-payment-plan';
import QuestionLabel from './question-label';

const { Title, Text, Paragraph } = Typography;

export const HolisticProjectRequestQuestions = {
  title: {
    Display: function DisplayTitle({ report, isAdmin }) {
      if (report) {
        const status = report && ReportApprovalStatuses[report.status];
        return (
          <Title
            style={{
              textAlign: 'center',
              margin: 32,
              marginBottom: isAdmin && report.status ? 16 : 32,
            }}
          >
            <span style={{ fontSize: 24, lineHeight: 1.5 }}>
              Holistic Project Proposal
            </span>
            <br />
            {report.humanReadableId && `#${report.humanReadableId} - `}
            {report.projectName}
            <br />
            <span style={{ fontSize: 24, lineHeight: 1.5 }}>
              {report.peopleGroups
                ? report.peopleGroups.map((p) => getPgDisplayName(p)).join(' ')
                : ''}
            </span>
            {isAdmin && status && (
              <>
                <br />
                <span style={{ fontSize: 18, color: status.color }}>
                  {status.label}
                </span>
              </>
            )}
          </Title>
        );
      }
      return (
        <Title style={{ textAlign: 'center', marginBottom: 32 }}>
          Holistic Project Proposal
        </Title>
      );
    },
  },
  submittedBy: reportSubmittedBy,
  approvedBy: reportApprovedBy,
  projectName: {
    FormItem: function ProjectNameFormItem({ loading }) {
      return (
        <Form.Item
          label="Project Name"
          name="projectName"
          rules={[{ required: true, message: 'Please enter a project name' }]}
        >
          <Input disabled={loading} />
        </Form.Item>
      );
    },
    getAnswer: (report) => report.projectName,
  },
  typeOfProject: {
    FormItem: function TypeOfProjectFormItem({ loading }) {
      return (
        <Form.Item
          label="Project Type"
          name="typeOfProject"
          rules={[{ required: true, message: 'Please select a project type' }]}
        >
          <Select disabled={loading}>
            {Object.entries(HolisticProjectTypes).map(([key, obj]) => (
              <Select.Option key={key} value={key}>
                {obj.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      );
    },
    Display: function DisplayTypeOfProject({ report }) {
      const label =
        report.typeOfProject && HolisticProjectTypes[report.typeOfProject]
          ? HolisticProjectTypes[report.typeOfProject].label
          : '';
      return <ReportLineItem question="Project Type" answer={label} />;
    },
  },
  desc: {
    FormItem: function DescFormItem({ loading }) {
      return (
        <Form.Item
          label="Project Description"
          name="desc"
          rules={[
            { required: true, message: 'Please enter a project description' },
          ]}
        >
          <Input.TextArea rows={4} disabled={loading} />
        </Form.Item>
      );
    },
    Display: function DisplayDesc({ report }) {
      return (
        <ReportLineItem question="Project Description" answer={report.desc} />
      );
    },
  },
  myPeopleGroups: {
    FormItem: function MyPeopleGroupsFormItem({
      loading,
      pgConnections,
      isAdmin,
    }) {
      return (
        <Form.Item label="Which people groups will be affected by this project?">
          <Form.List
            name="affectedPeopleGroups"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const persons = getFieldValue('affectedPeopleGroups');
                  if (persons.length === 0) {
                    return Promise.reject(
                      'At least one affected people group is required',
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item key={field.key} style={{ marginBottom: 0 }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <Form.Item
                        label={`People Group ${index + 1}`}
                        name={[field.name, 'peopleGroupId']}
                        style={{ width: '100%' }}
                        rules={[
                          {
                            required: true,
                            message: 'Please select a people group',
                          },
                        ]}
                      >
                        {isAdmin ? (
                          <SelectPeopleGroup disabled={loading} />
                        ) : (
                          <SelectFromMyPeopleGroups
                            pgConnections={pgConnections}
                            disabled={loading}
                          />
                        )}
                      </Form.Item>
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => {
                          remove(field.name);
                        }}
                        style={{ marginLeft: 8 }}
                      />
                    </div>
                  </Form.Item>
                ))}
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                    disabled={loading}
                  >
                    Add people group
                  </Button>
                </Form.Item>
                <Form.ErrorList errors={errors} />
              </>
            )}
          </Form.List>
        </Form.Item>
      );
    },
    before: (values) => {
      const beforeVals = {
        affectedPeopleGroups: values.peopleGroupIds
          ? values.peopleGroupIds.map((_id) => ({ peopleGroupId: _id }))
          : [{}],
      };
      return beforeVals;
    },
    finish: (values) => {
      const peopleGroupIds =
        values.affectedPeopleGroups &&
        values.affectedPeopleGroups.map((pgId) => pgId.peopleGroupId);
      values.peopleGroups = undefined;
      return {
        affectedPeopleGroups: undefined,
        peopleGroupIds,
      };
    },
    Display: function DisplayPeopleGroups({ report }) {
      const peopleGroups = report.peopleGroups
        .map((pg) => getPgDisplayName(pg))
        .join(', ');
      return (
        <ReportLineItem
          question="Which people groups will be affected by this project?"
          answer={peopleGroups}
        />
      );
    },
  },
  vision: {
    FormItem: function VisionFormItem({ loading }) {
      return (
        <>
          <QuestionLabel
            name="vision"
            title="Vision / Mission"
            subtitle="How will this project help reach the people groups listed above? How
            will they be impacted? Does it create access to these people groups?
            Will it help to improve their lives? How will it represent Jesus to
            them?"
          />
          <Form.Item
            name="vision"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input.TextArea rows={4} disabled={loading} />
          </Form.Item>
        </>
      );
    },
    Display: function DisplayVision({ report }) {
      return (
        <ReportLineItem
          question="Vision / Mission"
          desc="How will this project help reach the people groups listed above? How
          will they be impacted? Does it create access to these people groups?
          Will it help to improve their lives? How will it represent Jesus to
          them?"
          answer={report.vision}
        />
      );
    },
  },
  goals: {
    FormItem: function GoalsFormItem({ loading }) {
      return (
        <>
          <QuestionLabel
            name="goals"
            title="Goals"
            subtitle="How you will measure success?"
          />
          <Form.Item
            name="goals"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input.TextArea rows={4} disabled={loading} />
          </Form.Item>
        </>
      );
    },
    Display: function DisplayGoals({ report }) {
      return (
        <ReportLineItem
          question="Goals"
          answer={report.goals}
          desc="How you will measure success?"
        />
      );
    },
  },
  costAnalysis: {
    /** @param {{ loading: boolean, form: import('antd').FormInstance }} props **/
    FormItem: function CostAnalysisFormItem({ loading, form }) {
      const [loanTotalUsd, setLoanTotalUsd] = useState(
        form.getFieldValue('loanTotalUsd'),
      );
      const [exchangeRate, setExchangeRate] = useState(
        form.getFieldValue('exchangeRate'),
      );

      useEffect(() => {
        form.setFieldValue(
          'setupExpensesForeign',
          getNumberValue(loanTotalUsd * exchangeRate).toFixed(2),
        );
      }, [form, loanTotalUsd, exchangeRate]);

      return (
        <>
          <QuestionLabel
            name="loanTotalUsd"
            title="Loan Request Amount in U.S. Dollars"
            subtitle="Please note that this loan will be received and paid back in USD."
          />
          <Form.Item
            label=""
            name="loanTotalUsd"
            rules={[{ required: true, message: 'This field is required' }]}
            style={{ marginRight: 32, marginBottom: 16 }}
          >
            <InputNumber
              addonBefore="$"
              disabled={loading}
              min={0}
              step={10.0}
              style={{ width: 200 }}
              onChange={(val) => setLoanTotalUsd(val)}
            />
          </Form.Item>
          <Form.Item name="loanTotalForeign" noStyle>
            <Input type="hidden" />
          </Form.Item>

          <QuestionLabel
            name="loanTerm"
            title="Loan Term"
            subtitle="How many months until this loan is fully repaid?"
          />
          <Form.Item
            name="loanTerm"
            rules={[{ required: true, message: 'This field is required' }]}
            style={{ marginBottom: 16 }}
          >
            <Select disabled={loading} style={{ width: 200 }}>
              {[6, 12, 18, 24, 30, 36, 42, 48].map((months) => (
                <Select.Option key={months} value={months}>
                  {`${months} Months`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Form.Item
              name="currency"
              label="Name of Local Currency"
              rules={[{ required: true, message: 'This field is required' }]}
              style={{ marginBottom: 16, marginRight: 16 }}
            >
              <Input style={{ width: 300 }} disabled={loading} />
            </Form.Item>
            <Form.Item
              shouldUpdate={(prev, curr) => prev.currency !== curr.currency}
              noStyle
            >
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                  label="Exchange Rate from U.S. Dollars"
                  name="exchangeRate"
                  rules={[
                    { required: true, message: 'This field is required' },
                  ]}
                  style={{ marginBottom: 16, marginRight: 32 }}
                >
                  <ExchangeRateInput
                    disabled={loading}
                    localCurrency={getFieldValue('currency')}
                    onChange={(val) => setExchangeRate(val)}
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item
              shouldUpdate={(prev, curr) =>
                prev.loanTotalUsd !== curr.loanTotalUsd ||
                prev.currency !== curr.currency ||
                prev.exchangeRate !== curr.exchangeRate
              }
              noStyle
            >
              {({ getFieldValue }) => {
                let localCurrency;
                if (exchangeRate > 0) {
                  localCurrency = loanTotalUsd * exchangeRate;
                }
                return (
                  localCurrency !== undefined && (
                    <Form.Item
                      label="Loan total in your local currency"
                      style={{ marginBottom: 16 }}
                    >
                      <div>{`${getNumberValue(localCurrency).toFixed(2)} ${
                        getFieldValue('currency') || 'Local Currency'
                      }`}</div>
                    </Form.Item>
                  )
                );
              }}
            </Form.Item>
          </div>

          <div style={{ height: 16 }} />
          <div
            style={{
              marginBottom: 24,
              backgroundColor: sharedSettings.colors.borderGray,
              borderRadius: 8,
              padding: 16,
              textAlign: 'center',
              fontSize: 14,
            }}
          >
            The following questions help us to see if your project will be
            profitable. Please fill out the following in your local currency.
          </div>

          <QuestionLabel
            name="setupExpensesForeign"
            title="Setup Expenses (In Local Currency)"
            subtitle="Equipment, building, business fees, initial inventory ..."
          />
          <Form.Item
            name="setupExpensesForeign"
            style={{ marginRight: 32, marginBottom: 16 }}
            dependencies={['loanTotalUsd', 'exchangeRate']}
            extra="The loan amount and setup expenses must be the same."
          >
            <Input disabled style={{ width: 200 }} />
          </Form.Item>

          <Form.Item
            name="setupExpensesDesc"
            label="Please list and describe in detail your setup expenses including the cost of each item."
            rules={[{ required: true, message: 'This field is required' }]}
            style={{ marginBottom: 16 }}
          >
            <Input.TextArea rows={4} disabled={loading} />
          </Form.Item>

          <QuestionLabel
            name="recurringMonthlyExpensesForeign"
            title="Recurring Monthly Expenses (In Local Currency)"
            subtitle="Supplies, employee salaries, rent, inventory, maintenance, potential loss ..."
          />
          <Form.Item
            name="recurringMonthlyExpensesForeign"
            rules={[{ required: true, message: 'This field is required' }]}
            style={{ marginRight: 32, marginBottom: 16 }}
            extra="This should NOT include field worker support (Specified below)."
          >
            <InputNumber
              disabled={loading}
              min={0}
              step={10.0}
              style={{ width: 200 }}
            />
          </Form.Item>

          <Form.Item
            name="recurringMonthlyExpensesDesc"
            label="Please list and describe in detail your recurring monthly expenses including the cost of each item."
            rules={[{ required: true, message: 'This field is required' }]}
            style={{ marginBottom: 16 }}
          >
            <Input.TextArea rows={4} disabled={loading} />
          </Form.Item>

          {/* <QuestionLabel
            name="willGenerateRevenue"
            title="Revenue"
            subtitle="Will this project generate any revenue?"
            marginBottom={8}
          />
          <Form.Item
            name="willGenerateRevenue"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Radio.Group>
              <Radio value={true}>
                Yes, this project will generate revenue.
              </Radio>
              <Radio value={false}>
                No, this is purely a humanitarian project.
              </Radio>
            </Radio.Group>
          </Form.Item> */}

          <QuestionLabel
            name="recurringMonthlyRevenueForeign"
            title="Recurring Monthly Revenue (In Local Currency)"
            subtitle="How much money will this project generate each month?"
          />
          <Form.Item
            name="recurringMonthlyRevenueForeign"
            rules={[{ required: true, message: 'This field is required' }]}
            style={{ marginRight: 32, marginBottom: 16 }}
          >
            <InputNumber
              disabled={loading}
              min={0}
              step={10.0}
              style={{ width: 200 }}
            />
          </Form.Item>

          <Form.Item
            name="recurringMonthlyRevenueDesc"
            label="Please list and describe in detail your recurring monthly sources of revenue."
            rules={[{ required: true, message: 'This field is required' }]}
            style={{ marginBottom: 16 }}
          >
            <Input.TextArea rows={4} disabled={loading} />
          </Form.Item>
        </>
      );
    },
    finish: (values) => {
      if (values.exchangeRate > 0) {
        return {
          setupExpensesForeign: undefined,
          loanTotalForeign: values.loanTotalUsd * values.exchangeRate,
        };
      }
      return {
        setupExpensesForeign: undefined,
      };
    },
    Display: function Display({ colors, report }) {
      const setupExpensesDesc = report.setupExpensesDesc || '';
      const loanTotalForeign = getNumberValue(report.loanTotalForeign);
      const recurringMonthlyExpensesDesc =
        report.recurringMonthlyExpensesDesc || '';
      const recurringMonthlyExpensesForeign = getNumberValue(
        report.recurringMonthlyExpensesForeign,
      );
      const recurringMonthlyRevenueDesc =
        report.recurringMonthlyRevenueDesc || '';
      const recurringMonthlyRevenueForeign = getNumberValue(
        report.recurringMonthlyRevenueForeign,
      );
      let loanTotalUsd;
      let recurringMonthlyExpensesUsd;
      let recurringMonthlyRevenueUsd;
      const exchangeRate = getNumberValue(report.exchangeRate);
      if (exchangeRate > 0) {
        loanTotalUsd = `(${displayMoney(
          getNumberValue(loanTotalForeign / exchangeRate),
        )})`;
        recurringMonthlyExpensesUsd = `(${displayMoney(
          getNumberValue(recurringMonthlyExpensesForeign / exchangeRate),
        )})`;
        recurringMonthlyRevenueUsd = `(${displayMoney(
          getNumberValue(recurringMonthlyRevenueForeign / exchangeRate),
        )})`;
      }
      return (
        <>
          <ReportDescriptionAnswer
            colors={colors}
            question="Setup Expenses"
            desc="Please list and describe in detail your setup expenses."
            answer={
              <>
                <div style={{ marginBottom: 8 }}>
                  <Text
                    style={{ color: colors.primary, fontWeight: 'bold' }}
                  >{`Total: `}</Text>
                  <Text>{`${loanTotalForeign} ${loanTotalUsd}`}</Text>
                </div>
                {setupExpensesDesc.split('\n').map((p, index) => (
                  <Paragraph key={index}>{p}</Paragraph>
                ))}
              </>
            }
          />
          <ReportDescriptionAnswer
            colors={colors}
            question="Recurring Monthly Expenses"
            desc="Please list and describe in detail your recurring monthly expenses."
            answer={
              <>
                <div style={{ marginBottom: 8 }}>
                  <Text
                    style={{ color: colors.primary, fontWeight: 'bold' }}
                  >{`Total: `}</Text>
                  <Text>{`${recurringMonthlyExpensesForeign} ${recurringMonthlyExpensesUsd}`}</Text>
                </div>
                {recurringMonthlyExpensesDesc.split('\n').map((p, index) => (
                  <Paragraph key={index}>{p}</Paragraph>
                ))}
              </>
            }
          />
          <ReportDescriptionAnswer
            colors={colors}
            question="Recurring Monthly Revenue"
            desc="Please list and describe in detail your recurring monthly sources of revenue."
            answer={
              <>
                <div style={{ marginBottom: 8 }}>
                  <Text
                    style={{ color: colors.primary, fontWeight: 'bold' }}
                  >{`Total: `}</Text>
                  <Text>{`${recurringMonthlyRevenueForeign} ${recurringMonthlyRevenueUsd}`}</Text>
                </div>
                {recurringMonthlyRevenueDesc.split('\n').map((p, index) => (
                  <Paragraph key={index}>{p}</Paragraph>
                ))}
              </>
            }
          />
        </>
      );
    },
  },
  supportedFieldWorkers: {
    FormItem: function SupportedFieldWorkers({
      loading,
      form,
      regionalCoordId,
    }) {
      return (
        <>
          <Form.Item label="Please list the field workers that will be financially supported by this project">
            <Form.List
              name="supportedFieldWorkers"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const persons = getFieldValue('supportedFieldWorkers');
                    if (persons.length === 0) {
                      return Promise.reject(
                        'At least one supported field worker is required',
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item key={field.key} style={{ marginBottom: 0 }}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        {/* <Form.Item
                          label={`Field worker's name`}
                          name={[field.name, 'fieldWorkerName']}
                          style={{ flex: 1, paddingRight: 16 }}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter a field worker',
                            },
                          ]}
                        >
                          <Input disabled={loading} />
                        </Form.Item> */}
                        <Form.Item
                          label={`Field worker`}
                          name={[field.name, 'contactId']}
                          style={{ flex: 1, paddingRight: 16 }}
                          rules={[
                            {
                              required: true,
                              message: 'Please select a field worker',
                            },
                          ]}
                        >
                          <SelectFromMyFieldWorkers
                            regionalCoordId={regionalCoordId}
                          />
                        </Form.Item>
                        <Form.Item
                          label={`Amount per Month (In Local Currency)`}
                          name={[field.name, 'amountForeign']}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter an amount',
                            },
                          ]}
                        >
                          <InputNumber disabled={loading} min={0} step={10} />
                        </Form.Item>
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => {
                            remove(field.name);
                          }}
                          style={{ marginLeft: 8 }}
                        />
                      </div>
                    </Form.Item>
                  ))}
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                      disabled={loading}
                    >
                      Add field worker
                    </Button>
                  </Form.Item>
                  <Form.ErrorList errors={errors} />
                </>
              )}
            </Form.List>
          </Form.Item>
        </>
      );
    },
    finish: (values) => {
      const supportedFieldWorkers = orderAndFilterFormList(
        values.supportedFieldWorkers,
        (a, b) => {
          return a.contactId.localeCompare(b.contactId);
        },
        'contactId',
        ['contactId', 'fieldWorkerName', 'amountForeign'],
      );
      const responsiblePersons = orderAndFilterFormList(
        values.responsiblePersons,
        (a, b) => {
          return a.contactId.localeCompare(b.contactId);
        },
        'contactId',
        ['contactId', 'name', 'role', 'projectedHrsPerWeek', 'priorExperience'],
      );
      return {
        supportedFieldWorkers,
        responsiblePersons,
      };
    },
    Display: function Display({ colors, report }) {
      const exchangeRate = getNumberValue(report.exchangeRate);
      return (
        <div style={{ marginBottom: 16, paddingTop: 16 }}>
          <Title level={4}>Supported Field Workers</Title>
          {report && (
            <Table
              dataSource={report.supportedFieldWorkers || []}
              pagination={false}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No supported field workers"
                  />
                ),
              }}
            >
              <Table.Column
                title="Field Worker Name"
                dataIndex="fieldWorkerName"
                key="fieldWorkerName"
              />
              <Table.Column
                title="Amount"
                dataIndex="amountForeign"
                render={(text, record) => {
                  return getNumberValue(record.amountForeign);
                }}
              />
              <Table.Column
                title="USD"
                dataIndex="usd"
                render={(text, record) => {
                  if (exchangeRate > 0) {
                    return displayMoney(
                      getNumberValue(record.amountForeign / exchangeRate),
                    );
                  }
                  return null;
                }}
              />
            </Table>
          )}
          <div style={{ height: 16 }} />
        </div>
      );
    },
  },
  costAnalysisSummary: {
    FormItem: function CostAnalysisFormItem({ loading, form }) {
      return (
        <>
          <Form.Item
            shouldUpdate={(prev, curr) =>
              prev.exchangeRate !== curr.exchangeRate ||
              prev.loanTotalUsd !== curr.loanTotalUsd ||
              prev.loanTerm !== curr.loanTerm ||
              prev.recurringMonthlyExpensesForeign !==
                curr.recurringMonthlyExpensesForeign ||
              prev.recurringMonthlyRevenueForeign !==
                curr.recurringMonthlyRevenueForeign ||
              prev.supportedFieldWorkers !== curr.supportedFieldWorkers ||
              prev.currency !== curr.currency
            }
            noStyle
          >
            {({ getFieldValue }) => {
              const exchangeRate = getNumberValue(
                getFieldValue('exchangeRate'),
              );
              const currency = getFieldValue('currency');
              const loanTotalUsd = getNumberValue(
                getFieldValue('loanTotalUsd'),
              );
              const loanTerm = getNumberValue(getFieldValue('loanTerm'));
              const recurringMonthlyExpensesForeign = getNumberValue(
                getFieldValue('recurringMonthlyExpensesForeign'),
              );
              const recurringMonthlyRevenueForeign = getNumberValue(
                getFieldValue('recurringMonthlyRevenueForeign'),
              );
              const supportToFieldWorkers = (
                getFieldValue('supportedFieldWorkers') || []
              ).reduce((prev, curr) => {
                return prev + getNumberValue(curr && curr.amountForeign);
              }, 0);
              return (
                <>
                  <Divider>Cost Analysis</Divider>
                  <CostAnalysisSummary
                    exchangeRate={exchangeRate}
                    loanTotalUsd={loanTotalUsd}
                    loanTerm={loanTerm}
                    recurringMonthlyExpensesForeign={
                      recurringMonthlyExpensesForeign
                    }
                    recurringMonthlyRevenueForeign={
                      recurringMonthlyRevenueForeign
                    }
                    supportToFieldWorkers={supportToFieldWorkers}
                    currency={currency}
                    isForm
                  />
                  <Divider />
                </>
              );
            }}
          </Form.Item>
        </>
      );
    },
    Display: function Display({ report }) {
      const exchangeRate = getNumberValue(report.exchangeRate);
      const loanTotalUsd = getNumberValue(report.loanTotalUsd);
      const loanTerm = getNumberValue(report.loanTerm);
      const setupExpensesForeign = getNumberValue(
        report.loanTotalUsd * report.exchangeRate,
      );
      const recurringMonthlyExpensesForeign = getNumberValue(
        report.recurringMonthlyExpensesForeign,
      );
      const recurringMonthlyRevenueForeign = getNumberValue(
        report.recurringMonthlyRevenueForeign,
      );
      const supportedFieldWorkers = report.supportedFieldWorkers;
      const supportToFieldWorkers = (supportedFieldWorkers || []).reduce(
        (prev, curr) => {
          return prev + getNumberValue(curr && curr.amountForeign);
        },
        0,
      );
      return (
        <>
          <Title level={4}>Cost Analysis</Title>
          <CostAnalysisSummary
            exchangeRate={exchangeRate}
            loanTotalUsd={loanTotalUsd}
            loanTerm={loanTerm}
            setupExpensesForeign={setupExpensesForeign}
            recurringMonthlyExpensesForeign={recurringMonthlyExpensesForeign}
            recurringMonthlyRevenueForeign={recurringMonthlyRevenueForeign}
            supportToFieldWorkers={supportToFieldWorkers}
            currency={report.currency}
          />
          <div style={{ height: 32 }} />
        </>
      );
    },
  },
  loanTotal: {
    FormItem: function LoanTotalFormItem({ loading }) {
      return (
        <Form.Item
          label="Loan Total"
          name="loanTotal"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <InputNumber
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            disabled={loading}
            min={0}
            step={10.0}
            style={{ width: 200 }}
          />
        </Form.Item>
      );
    },
    getAnswer: (report) =>
      `$${report.loanTotal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    Display: function DisplayLoanTotal({ report }) {
      return (
        <ReportLineItem
          question="Loan Total"
          answer={displayMoney(report.loanTotal)}
        />
      );
    },
  },
  loanDetails: {
    FormItem: function LoanDetailsFormItem({ loading }) {
      return (
        <>
          <QuestionLabel
            name="loanDetails"
            title="Loan Details"
            subtitle="Who will receive these payments? Where are the received payments being held?"
          />
          <Form.Item
            name="loanDetails"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input.TextArea rows={4} disabled={loading} />
          </Form.Item>
        </>
      );
    },

    Display: function DisplayLoanDetails({ report }) {
      return (
        <ReportLineItem
          question="Loan Details"
          desc="This is your loan repayment plan. When will you make your first payment?  How often will you make payments? How much will you pay each time? Who will receive these payments?  Where are the received payments being held?"
          answer={report.loanDetails}
        />
      );
    },
  },
  holisticPaymentPlan,
  marketAnalysis: {
    FormItem: function MarketAnalysisFormItem({ loading }) {
      return (
        <>
          <QuestionLabel
            name="marketAnalysis"
            title="Market Analysis"
            subtitle="Describe the current state of your industry. Is there a need for
            your item/service? Who are your actual customers? Are they currently
            buying this product or service from other people? How much are they
            paying for this item/service? What will you be charging for your
            item/services?"
          />
          <Form.Item
            name="marketAnalysis"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input.TextArea rows={4} disabled={loading} />
          </Form.Item>
        </>
      );
    },
    Display: function DisplayMarketAnalysis({ report }) {
      return (
        <ReportLineItem
          question="Market Analysis"
          desc="Describe the current state of your industry. Is there a need for your item/service? Who are your actual customers? Are they currently buying this product or service from other people? How much are they paying for this item/service? What will you be charging for your item/services?"
          answer={report.marketAnalysis}
        />
      );
    },
  },
  otherProfitUses: {
    FormItem: function OtherProfitUsesFormItem({ loading }) {
      return (
        <Form.Item
          label="How else will the profit from this project be used?"
          name="otherProfitUses"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Input.TextArea rows={4} disabled={loading} />
        </Form.Item>
      );
    },
    Display: function DisplayOtherProfitUses({ report }) {
      return (
        <ReportLineItem
          question="How else will the profit from this project be used?"
          answer={report.otherProfitUses}
        />
      );
    },
  },
  riskAnalysis: {
    FormItem: function FormItem({ loading }) {
      return (
        <Form.Item
          label="What are the risks?  What could go wrong?  And how will you attempt to prevent those risks?"
          name="riskAnalysis"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Input.TextArea rows={4} disabled={loading} />
        </Form.Item>
      );
    },
    Display: function Display({ report }) {
      return (
        <ReportLineItem
          question="What are the risks?  What could go wrong?  And how will you attempt to prevent those risks?"
          answer={report.riskAnalysis}
        />
      );
    },
  },
  locationRisk: {
    FormItem: function FormItem({ loading }) {
      return (
        <>
          <QuestionLabel
            name="locationRisk"
            title="Location"
            subtitle="Where will this project be located? Is the property owned, rented or
            donated?"
          />
          <Form.Item
            name="locationRisk"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <Input.TextArea rows={4} disabled={loading} />
          </Form.Item>
        </>
      );
    },
    Display: function Display({ report }) {
      return (
        <ReportLineItem
          question="Location"
          desc="Where will this project be located?  Is the property owned, rented or donated?"
          answer={report.locationRisk}
        />
      );
    },
  },
  responsiblePersons: {
    FormItem: function FormItem({ loading, regionalCoordId }) {
      return (
        <Form.Item label="Who will be taking responsibility for the planning, management and implementation of this project?">
          <Form.List
            name="responsiblePersons"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const persons = getFieldValue('responsiblePersons');
                  if (persons.length === 0) {
                    return Promise.reject(
                      'At least one responsible person is required',
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item key={field.key} style={{ marginBottom: 0 }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <div
                        style={{
                          border: `1px solid ${sharedSettings.colors.borderGray}`,
                          borderRadius: 4,
                          padding: 16,
                          flex: 1,
                          marginBottom: 16,
                        }}
                      >
                        <Form.Item
                          label={`Name`}
                          name={[field.name, 'contactId']}
                          style={{ flex: 1, paddingRight: 16 }}
                          rules={[
                            {
                              required: true,
                              message: 'Please select a field worker',
                            },
                          ]}
                        >
                          <SelectFromMyFieldWorkers
                            regionalCoordId={regionalCoordId}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Role"
                          name={[field.name, 'role']}
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                            },
                          ]}
                        >
                          <Input disabled={loading} />
                        </Form.Item>
                        <Form.Item
                          label="How many hours will the project require of this person every week?"
                          name={[field.name, 'projectedHrsPerWeek']}
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                            },
                          ]}
                        >
                          <InputNumber
                            disabled={loading}
                            min={0}
                            step={1}
                            style={{ width: 200 }}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Please describe this person's related prior experience."
                          name={[field.name, 'priorExperience']}
                          rules={[
                            {
                              required: true,
                              message: 'This field is required',
                            },
                          ]}
                        >
                          <Input.TextArea rows={4} disabled={loading} />
                        </Form.Item>
                      </div>
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => {
                          remove(field.name);
                        }}
                        style={{ marginLeft: 8 }}
                      />
                    </div>
                  </Form.Item>
                ))}
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add responsible person
                  </Button>
                </Form.Item>
                <Form.ErrorList errors={errors} />
              </>
            )}
          </Form.List>
        </Form.Item>
      );
    },
    Display: function Display({ colors, report }) {
      return (
        <div style={{ marginBottom: 16 }}>
          <div style={{ marginBottom: 16 }}>
            {`Who will be taking responsibility for the planning, management and implementation of this project?`}
          </div>
          <List
            dataSource={report.responsiblePersons || []}
            renderItem={(person) => (
              <div style={{ marginBottom: 16 }}>
                <div
                  style={{
                    textAlign: 'center',
                    backgroundColor: colors.backgroundGray,
                    borderRadius: 8,
                    padding: 12,
                    fontSize: 14,
                  }}
                >
                  <ReportInfoItem label="Name" value={person.name} />
                  <ReportInfoItem label="Role" value={person.role} />
                  <ReportInfoItem
                    label="Hours/Week"
                    value={person.projectedHrsPerWeek}
                  />
                  <ReportInfoItem
                    label="Related Experience"
                    value={person.priorExperience}
                  />
                </div>
              </div>
            )}
            bordered
            style={{
              paddingRight: 20,
              paddingLeft: 20,
              paddingTop: 20,
              paddingBottom: 4,
            }}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No Responsible Persons"
                />
              ),
            }}
          />
        </div>
      );
    },
  },
  publicFiles: {
    ...reportPublicFiles,
    FormItem: function FormItem(props) {
      return reportPrivateFiles.FormItem({
        ...props,
        reportType: 'HOLISTIC_REQ',
      });
    },
  },
  privateFiles: {
    ...reportPrivateFiles,
    FormItem: function FormItem(props) {
      return reportPrivateFiles.FormItem({
        ...props,
        reportType: 'HOLISTIC_REQ',
      });
    },
  },
  onBehalfOf: reportOnBehalfOf,
  onBehalfOfName: reportOnBehalfOfName,
  notes: reportNotes,
};
