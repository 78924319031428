import { Typography } from 'antd';
import React from 'react';
import sharedSettings from '../../sharedSettings';

const { Text, Paragraph } = Typography;

function QuestionLabel({ name, title, subtitle, marginBottom }) {
  return (
    <>
      <label htmlFor={name} style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ marginRight: 4 }}>
          <Text type="danger">*</Text>
        </div>
        <div>
          <Text style={{ color: sharedSettings.colors.primary }}>{title}</Text>
        </div>
      </label>
      <Paragraph style={{ marginBottom }}>{subtitle}</Paragraph>
    </>
  );
}

export default QuestionLabel;
