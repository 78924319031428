export const FinanceReportTypes = {
  TRANSFERS: {
    key: 'TRANSFERS',
    label: 'Transfers Report',
  },
  BALANCES: {
    key: 'BALANCES',
    label: 'Balances Report',
  },
};
