import { Form, Input, Select, Typography } from 'antd';
import React from 'react';
import SelectCountry from '../../components/SelectCountry';
import { PotentalTrainerRoles } from './constants';
import { useCountries } from '../../shared/use-countries';
import sharedSettings from '../../sharedSettings';
import {
  MailOutlined,
  PhoneOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';

const { Text } = Typography;

function useCountryName(shortCode) {
  const { countries } = useCountries();
  let countryName;
  if (countries && countries.length && shortCode) {
    const country = countries.find((c) => c.shortCode === shortCode);
    countryName = country && country.name;
  }
  return countryName;
}

export function DisplayTrainerContactInfo({ contactInfo, showOrg }) {
  const countryName = useCountryName(contactInfo?.country);
  return (
    <div style={{ lineHeight: 1.4, textAlign: 'center' }}>
      <Text strong>{contactInfo.name}</Text>
      <br />
      {PotentalTrainerRoles[contactInfo.potentialRole]?.label ||
        'No potential role specified'}
      <br />
      <Text>
        <MailOutlined /> {contactInfo.email || 'No email'}
      </Text>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ marginRight: 8 }}>
          <PhoneOutlined /> {contactInfo.phone || 'No phone'}
        </div>
        <div>
          <WhatsAppOutlined /> {contactInfo.whatsApp || 'No WhatsApp'}
        </div>
      </div>
      {countryName || 'No country'}
      {showOrg && (
        <>
          <br />
          {contactInfo.orgName || 'No organization or church name'}
        </>
      )}
    </div>
  );
}

function TrainingContactInfo({ fieldNames, loading, showOrg }) {
  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          width: '100%',
        }}
      >
        <Form.Item name={[...fieldNames, '_id']} noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          label="Name"
          name={[...fieldNames, 'name']}
          style={{ marginRight: 16, minWidth: 300, flex: 1 }}
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item
          label="Email"
          name={[...fieldNames, 'email']}
          rules={[
            {
              type: 'email',
              message: 'Please enter a valid email (ex. aaron@gmail.com).',
            },
          ]}
          style={{ marginRight: 16, minWidth: 300, flex: 1 }}
        >
          <Input disabled={loading} />
        </Form.Item>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          width: '100%',
        }}
      >
        <Form.Item
          label="Phone"
          name={[...fieldNames, 'phone']}
          extra="To add an international number, enter the + symbol, followed by the country code, and then the number."
          rules={[
            {
              pattern: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
              message: 'Please enter a valid phone number (ex. 5124026225).',
            },
          ]}
          style={{ marginRight: 16, minWidth: 300, flex: 1 }}
        >
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item
          label="WhatsApp"
          name={[...fieldNames, 'whatsApp']}
          extra="To add an international number, enter the + symbol, followed by the country code, and then the number."
          rules={[
            {
              pattern: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
              message: 'Please enter a valid phone number (ex. 5124026225).',
            },
          ]}
          style={{ marginRight: 16, minWidth: 300, flex: 1 }}
        >
          <Input disabled={loading} />
        </Form.Item>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          width: '100%',
        }}
      >
        <Form.Item
          label="Country"
          name={[...fieldNames, 'country']}
          style={{ marginRight: 16, minWidth: 300, flex: 1 }}
        >
          <SelectCountry />
        </Form.Item>
        {showOrg && (
          <Form.Item
            label="Name of Church or Organization"
            name={[...fieldNames, 'orgName']}
            style={{ marginRight: 16, minWidth: 300, flex: 1 }}
          >
            <Input disabled={loading} />
          </Form.Item>
        )}
        <Form.Item
          label="Potential Role"
          name={[...fieldNames, 'potentialRole']}
          style={{ marginRight: 16, minWidth: 300, flex: 1 }}
        >
          <Select
            options={Object.values(PotentalTrainerRoles).map((o) => ({
              value: o.key,
              label: o.label,
            }))}
          />
        </Form.Item>
      </div>
    </div>
  );
}

export default TrainingContactInfo;
