import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import useSingleSomethingUpdatesA10 from '@aims/shared/shared/use-single-something-updates-a10';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useRef, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import CSPage from '../../../../components/CSPage';
import CSPageHeader from '../../../../components/CSPageHeader';
import {
  createTrainingCourseAction,
  deleteTrainingCourseAction,
  updateTrainingCourseAction,
} from '../../../../redux-store/training-courses-store';
import settings from '../../../../settings';
import {
  allTrainingFilesQuery,
  extractAllTrainingFiles,
  trainingFileUpdatesSubscription,
} from '../../training_files/constants';
import {
  extractTrainingCourse,
  trainingCourseQuery,
  trainingCourseUpdatesSubscription,
} from '../constants';
import ModuleItem from './ModuleItem';
import TrainingCourseTitle from './TrainingCourseTitle';
import {
  createTrainingFileAction,
  deleteTrainingFileAction,
  readTrainingFilesAction,
  updateTrainingFileAction,
} from '../../../../redux-store/training-files-store';
import useManyLocalM20 from '@aims/shared/shared/use-many-local-m20';
import useManySomethingsM20 from '@aims/shared/shared/use-many-somethings-m20';
import TrainingFileModal from '../../training_files/TrainingFileModal';
import DeleteTrainingFileModal from '../../training_files/DeleteTrainingFileModal';

const queryId = 'viewTrainingCourse';

function ViewTrainingCourse() {
  const history = useHistory();

  const { _id } = useParams();
  useSingleSomethingUpdatesA10({
    variables: { _id },
    subscription: trainingCourseUpdatesSubscription,
    createAction: createTrainingCourseAction,
    updateAction: updateTrainingCourseAction,
    deleteAction: deleteTrainingCourseAction,
    extractSomething: (resp) => resp?.data?.trainingCourseUpdatesForAdmin,
    queryId,
    skip: !_id,
  });
  const { loading: courseLoading } = useOneM1({
    variables: { _id },
    query: trainingCourseQuery,
    extract: extractTrainingCourse,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: updateTrainingCourseAction,
    skip: !_id,
    showError: true,
  });
  const course = useSingleSomethingA10('trainingCourses', _id, queryId);

  const filters = useRef({ courseId: _id });
  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);
  const { error } = useSingleSomethingUpdatesA10({
    variables: { filters: filters.current },
    subscription: trainingFileUpdatesSubscription,
    createAction: createTrainingFileAction,
    updateAction: updateTrainingFileAction,
    deleteAction: deleteTrainingFileAction,
    extractSomething: (resp) => resp?.data?.trainingFileUpdatesForAdmin,
    queryId,
  });
  const { loading: filesLoading } = useManyLocalM20({
    query: allTrainingFilesQuery,
    extract: extractAllTrainingFiles,
    readAction: readTrainingFilesAction,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
  });
  const { data: files, search: fileSearch } = useManySomethingsM20({
    filterFunc: (data) => {
      if (data.courseId !== _id) {
        return false;
      }
      return true;
    },
    table: 'trainingFiles',
    sortByFunc: (a, b) => {
      return b.updatedAt?.localeCompare(a.updatedAt);
    },
    searchKeys: ['fileName', 'desc', '_id'],
    queryId,
  });

  const loading = courseLoading || filesLoading;

  const [editingFile, setEditingFile] = useState();
  const onEditFile = (editing) => setEditingFile(editing);
  const onCancelEditFile = () => setEditingFile(undefined);
  const onFinishEditFile = () => {
    setEditingFile(undefined);
  };

  const [deletingFile, setDeletingFile] = useState();
  const onDeleteFile = (o) => setDeletingFile(o);
  const onCancelDeleteFile = () => setDeletingFile(undefined);
  const onFinishDeleteFile = () => {
    setDeletingFile(undefined);
  };

  return (
    <CSPage title="View Training Course">
      <CSPageHeader
        titleComponent={<TrainingCourseTitle course={course} />}
        backActions={[
          <Link key="back" to="/training-courses">
            <Button type="text" icon={<ArrowLeftOutlined />}>
              Back to Training Courses
            </Button>
          </Link>,
        ]}
        style={{ marginBottom: 16 }}
      />
      {course &&
        course.modules &&
        course.modules.map((m) => (
          <ModuleItem
            key={m._id}
            module={m}
            files={files}
            loading={loading}
            onEditFile={onEditFile}
            onDeleteFile={onDeleteFile}
          />
        ))}
      <TrainingFileModal
        visible={editingFile}
        onCancel={onCancelEditFile}
        onFinish={onFinishEditFile}
      />
      <DeleteTrainingFileModal
        visible={deletingFile}
        onCancel={onCancelDeleteFile}
        onFinish={onFinishDeleteFile}
      />
    </CSPage>
  );
}

export default ViewTrainingCourse;
