import { EditOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, Typography } from 'antd';
import React, { useEffect, useMemo } from 'react';
import EditThingAccountModal from './EditThingAccountModal';
import TaskSomethingForm from '../../tasks/ViewTaskModal/TaskSomethingForm';

const { Title, Paragraph } = Typography;

function AccountNotesFormItems({ saving }) {
  return (
    <Form.Item label="Notes" name="notes">
      <Input.TextArea disabled={saving} rows={4} />
    </Form.Item>
  );
}

function AccountNotes({ account }) {
  const notes = useMemo(() => {
    if (account?.notes) {
      return account.notes.split('\n');
    }
    return undefined;
  }, [account]);
  const [form] = Form.useForm();
  useEffect(() => {
    if (account) {
      form.setFieldsValue({
        notes: account.notes,
      });
    }
  }, [form, account]);
  return (
    <div>
      <div style={{ display: 'flex', maxWidth: 600 }}>
        <Title level={4}>Account Notes</Title>
        <div style={{ flex: 1 }} />
        <EditThingAccountModal
          account={account}
          btnText="Edit Notes"
          btnProps={{ type: 'text', icon: <EditOutlined /> }}
        >
          <TaskSomethingForm form={form} title="Account Notes">
            <AccountNotesFormItems />
          </TaskSomethingForm>
        </EditThingAccountModal>
      </div>
      <Card style={{ maxWidth: 600 }} styles={{ body: { paddingBottom: 8 } }}>
        {notes?.map((note, index) => (
          <Paragraph key={index}>{note}</Paragraph>
        ))}
        {!notes && <Paragraph>Add notes here...</Paragraph>}
      </Card>
    </div>
  );
}

export default AccountNotes;
