import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { generateUuid } from '@aims/shared/shared/utils';
import { DownloadOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip, Typography } from 'antd';
import React, { useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import settings from '../../../settings';
import { allAdoptionsQuery, extractAdoptions } from '../constants';
import ListAdoptionsA10 from './ListAdoptionsA10';
import { CSVLink } from 'react-csv';
import moment from 'moment';

const { Text } = Typography;

const queryId = 'adoptionsPage';

const headers = [
  { label: 'Adoption ID', key: '_id' },
  { label: 'Sponsor 1 ID', key: 'sponsors.0._id' },
  { label: 'Sponsor 1 Name', key: 'sponsors.0.fullName' },
  { label: 'Sponsor 2 ID', key: 'sponsors.1._id' },
  { label: 'Sponsor 2 Name', key: 'sponsors.1.fullName' },
  { label: 'Sponsor 3 ID', key: 'sponsors.2._id' },
  { label: 'Sponsor 3 Name', key: 'sponsors.2.fullName' },
  { label: 'Sponsor 4 ID', key: 'sponsors.3._id' },
  { label: 'Sponsor 4 Name', key: 'sponsors.3.fullName' },
  { label: 'People Group ID', key: 'peopleGroup._id' },
  { label: 'People Group Name', key: 'peopleGroup.nameAcrossCountries' },
  { label: 'People Group Country', key: 'peopleGroup.country' },
  { label: 'People Group Region', key: 'peopleGroup.region' },
  { label: 'Region Code', key: 'regionCode' },
  { label: 'Field Worker ID', key: 'fieldWorkerId' },
  { label: 'Field Worker Name', key: 'fieldWorkerName' },
  { label: 'Regional Coordinator ID', key: 'regionalCoordId' },
  { label: 'Regional Coordinator Name', key: 'regionalCoordName' },
  { label: 'Status', key: 'status' },
  { label: 'Recurring Payment ID 1', key: 'recurringPaymentIds.0' },
  { label: 'Recurring Payment ID 2', key: 'recurringPaymentIds.1' },
  { label: 'Recurring Payment ID 3', key: 'recurringPaymentIds.2' },
  { label: 'Amount', key: 'amount' },
  { label: 'Account ID', key: 'accountId' },
  { label: 'Tag 1', key: 'tags.0.key' },
  { label: 'Tag 2', key: 'tags.1.key' },
  { label: 'Tag 3', key: 'tags.2.key' },
  { label: 'Tag 4', key: 'tags.3.key' },
  { label: 'Start Date', key: 'startDate' },
  { label: 'End Date', key: 'endDate' },
  { label: 'First Payment', key: 'firstPaymentDate' },
  { label: 'Last Payment', key: 'lastPaymentDate' },
  { label: 'Number of Payouts', key: 'numOfPayoutQuarters' },
  { label: 'Fulfilled through Online Catalog', key: 'isOnline' },
  { label: 'Checked Commit to Adopt', key: 'commitToAdopt' },
  { label: 'Notes', key: 'notes' },
  { label: 'Updated At', key: 'updatedAt' },
];

function ListAdoptionsPageA10() {
  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const filters = useRef({});
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);

  const {
    error,
    loading,
    data: adoptions,
    search: adoptionSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: allAdoptionsQuery,
    extract: extractAdoptions,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
  });

  const onFiltersChanged = useCallback(
    (_changed) => {
      const { search, ...changed } = _changed;
      if (search != undefined) {
        sortBy.current = [
          { key: '_score', order: 'DESC' },
          {
            key: sortedBy,
            order: sortOrder,
          },
        ];
        adoptionSearch(search);
      } else {
        filters.current = {
          ...filters.current,
          ...Object.entries(changed).reduce((prev, [k, v]) => {
            prev[k] = v != null ? v : undefined;
            return prev;
          }, {}),
        };
        refetch();
      }
    },
    [adoptionSearch, refetch, sortedBy, sortOrder],
  );

  const onSortOrderChange = useCallback(
    (value) => {
      sortBy.current = [
        {
          key: sortedBy,
          order: value,
        },
        { key: '_score', order: 'DESC' },
      ];
      refetch();
      setSortOrder(value);
    },
    [sortedBy, refetch],
  );

  const onSortedByChange = useCallback(
    (value) => {
      sortBy.current = [
        {
          key: value,
          order: sortOrder,
        },
      ];
      refetch();
      setSortedBy(value);
    },
    [sortOrder, refetch],
  );

  return (
    <CSPage title="UPG Sponsorships" containerStyle={{ maxWidth: 'unset' }}>
      <CSPageHeader
        title="UPG Sponsorships"
        topActions={[
          <Link key="org" to={`/adoptions/new/${generateUuid()}`}>
            <Button
              key="add"
              type="link"
              icon={<PlusCircleOutlined />}
              size="large"
            >
              Add a new sponsorship
            </Button>
          </Link>,
          <Tooltip
            title="This will export the current search results, or in other words, the UPG Sponsorships displayed in the table below."
            key="export"
          >
            <CSVLink
              data={adoptions}
              headers={headers}
              filename={`upg_sponsorships(${moment(new Date()).format(
                'MM-DD-YYYY',
              )})`}
            >
              <DownloadOutlined style={{ marginRight: '8px' }} />
              Export CSV
            </CSVLink>
          </Tooltip>,
        ]}
      />
      <ListAdoptionsA10
        loading={loading}
        error={error}
        adoptions={adoptions}
        next={next}
        hasNextPage={hasNextPage}
        refetch={refetch}
        isTemplate={false}
        onFiltersChanged={onFiltersChanged}
        onSortedByChange={onSortedByChange}
        onSortOrderChange={onSortOrderChange}
      />
    </CSPage>
  );
}

export default ListAdoptionsPageA10;
