import sharedSettings from '@aims/shared/sharedSettings';
import { Layout } from 'antd';
import React, { useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import Error404 from '../../components/Error404';
import Footer from '../../components/Footer';
import MainNavMenu from '../../components/MainNavMenu';
import EditContact from '../../pages/contacts/EditContact';
import ViewContact from '../../pages/contacts/ViewContact';
import Dashboard from '../../pages/dashboard/Dashboard';
import ListEventsPage from '../../pages/events/ListEventsPage';
import EditHolisticProject from '../../pages/holistic-projects/EditHolisticProject';
import ViewHolisticProject from '../../pages/holistic-projects/ViewHolisticProject/ViewHolisticProject';
import EditPeopleGroup from '../../pages/people_groups/EditPeopleGroup';
import ListPeopleGroups from '../../pages/people_groups/ListPeopleGroups';
import ViewPeopleGroup from '../../pages/people_groups/ViewPeopleGroup';
import EditTask from '../../pages/tasks/EditTask';
import EditUser from '../../pages/users/EditUser';
import ViewUser from '../../pages/users/ViewUser';
import AllProjectSponsorshipsPage from '../../project-sponsorships/AllProjectSponsorshipsPage';
import ViewSponsorshipPage from '../../project-sponsorships/ViewSponsorship/ViewSponsorshipPage';
import useProfileUpdates from '../../shared/use-profile-updates';
import useQueryParams from '../../shared/use-query-params';
import AccountingHomePage from '../accounting/accounts-j25/AccountingHomePage';
import ViewAccountPage from '../accounting/accounts-j25/ViewAccountPage';
import EditPayoutV2 from '../accounting/payoutsV2/EditPayoutV2';
import PayoutsV2Page from '../accounting/payoutsV2/PayoutsV2Page';
import ListAdoptionStatsPage from '../adoption-stats/ListAdoptionStatsPage';
import ViewAdoption from '../adoptions/ViewAdoption';
import CreateAdoptionPageA10 from '../adoptions/a10-adoptions/CreateAdoptionPageA10';
import ListAdoptionsPageA10 from '../adoptions/a10-adoptions/ListAdoptionsPageA10';
import PeoplePage from '../contacts/PeoplePage';
import ListHolisticProjectsPage from '../holistic-projects/ListHolisticProjectsPage';
import ViewLoanAgreementPage from '../holistic-projects/ViewHolisticProject/tabs/LoanAgreementsTab/ViewLoanAgreementPage';
import EditProposal from '../holistic-proposals/EditProposal';
import ListHolisticProjectProposalsPage from '../holistic-proposals/ListHolisticProjectProposalsPage';
import ViewHolisticProposal from '../holistic-proposals/ViewHolisticProposal';
import EditHolisticQuarterlyReport from '../holistic-quarterly-reports/EditHolisticQuarterlyReport';
import ListHolisticProjectReportsPage from '../holistic-quarterly-reports/ListHolisticProjectReportsPage';
import ViewHolisticQuarterlyReport from '../holistic-quarterly-reports/ViewHolisticQuarterlyReport';
import ListLoanAgreementTemplatesPage from '../loan-agreements/ListLoanAgreementTemplatesPage';
import ViewLoanAgreementTemplate from '../loan-agreements/ViewLoanAgreementTemplate';
import MiraclesPage from '../miracles/MiraclesPage';
import ListMouTemplatesPage from '../mous/ListMouTemplatesPage';
import ListMousPage from '../mous/ListMousPage';
import ViewMouPage from '../mous/ViewMouPage';
import ViewMouTemplate from '../mous/ViewMouTemplate';
import PrayerRequestPage from '../prayer-requests/PrayerRequestPage';
import PublicFormsList from '../public-forms/PublicFormsList';
import ReviewPublicForm from '../public-forms/ReviewPublicForm';
import PushPayItems from '../pushpay/PushpayItems';
import SavedImagesPage from '../saved-images/SavedImagesPage';
import ConstantContactAuthResultPage from '../settings/ConstantContactAuthResultPage';
import SettingsPage from '../settings/SettingsPage';
import ManageTagsPage from '../tags/ManageTagsPage';
import ListTasks from '../tasks/ListTasksPage';
import ManageLanguagesPage from '../training-languages/ManageLanguagesPage';
import EditTrainingEventReport from '../training/event-reports/EditTrainingEventReport';
import TrainingEventReportsPage from '../training/event-reports/TrainingEventReportsPage';
import ViewTrainingEventReport from '../training/event-reports/ViewTrainingEventReport';
import EditTrainingQuarterlyReport from '../training/quarterly-reports/EditTrainingQuarterlyReport';
import TrainingQuarterlyReportsPage from '../training/quarterly-reports/TrainingQuarterlyReportsPage';
import ViewTrainingQuarterlyReportPage from '../training/quarterly-reports/ViewTrainingQuarterlyReportPage';
import AllTrainingCoursesPage from '../training/training_courses/ListCourses/AllTrainingCoursesPage';
import ViewTrainingCourse from '../training/training_courses/ViewCourse/ViewTrainingCourse';
import AllTrainingFilesPage from '../training/training_files/AllTrainingFilesPage';
import ListUpgPrayerAdoptionsPage from '../upg-prayer-adoptions/ListUpgPrayerAdoptionsPage';
import ViewUpgPrayerAdoptionPage from '../upg-prayer-adoptions/ViewUpgPrayerAdoptionPage';
import EditUpgQuarterlyReport from '../upg-quarterly-reports/EditUpgQuarterlyReport';
import UpgQuarterlyReportsPage from '../upg-quarterly-reports/UpgQuarterlyReportsPage';
import ViewUpgQuarterlyReport from '../upg-quarterly-reports/ViewUpgQuarterlyReport';
import EditProfile from '../users/EditUser/EditProfile';
import AllUsersPage from '../users/AllUsersPage';
import ViewFinanceReportPage from '../accounting/finance-reports/ViewFinanceReportPage';

function MainRouter() {
  const responsiveMode = useSelector(
    (store) => store.responsiveMode,
    shallowEqual,
  );

  const query = useQueryParams();
  const forwardAfterLogin = useCallback(() => {
    const { p } = query;
    if (p) {
      return <Redirect to={p} />;
    }
    return <Redirect to="/home" />;
  }, [query]);

  useProfileUpdates();

  return (
    <Layout
      hasSider={responsiveMode !== 'mobile'}
      style={{
        minHeight: '100vh',
        background: sharedSettings.colors.ghost,
      }}
    >
      <MainNavMenu />
      <Layout
        style={{
          background: sharedSettings.colors.ghost,
          boxShadow:
            responsiveMode !== 'mobile'
              ? 'inset 1px 0px 10px rgba(0, 0, 0, 0.05)'
              : undefined,
        }}
      >
        {/* <ScrollToTop> */}
        <Switch>
          <Route exact path="/" render={forwardAfterLogin} />
          <Route exact path="/sign-(in|up)" render={forwardAfterLogin} />
          <Route exact path="/home" component={Dashboard} />
          <Route exact path="/users" component={AllUsersPage} />
          <Route exact path="/user/view/:_id/:tab?" component={ViewUser} />
          <Route exact path="/user/edit/:_id" component={EditUser} />
          <Route
            exact
            path="/contact/edit/:_id/:type?"
            component={EditContact}
          />
          <Route exact path="/people/:tab?" component={PeoplePage} />
          <Route
            exact
            path="/contact/view/:_id/:tab?"
            component={ViewContact}
          />
          <Route exact path="/events/:eventId?" component={ListEventsPage} />
          <Route exact path="/tasks/:taskId?" component={ListTasks} />
          <Route exact path="/task/edit/:_id" component={EditTask} />
          <Route
            exact
            path="/people-group/edit/:_id"
            component={EditPeopleGroup}
          />
          <Route
            exact
            path="/people-group/:peopleGroupId/report/edit/:reportId"
            component={EditUpgQuarterlyReport}
          />
          <Route
            exact
            path="/people-group/:peopleGroupId/report/review/:reportId"
            component={ViewUpgQuarterlyReport}
          />
          <Route exact path="/people-groups" component={ListPeopleGroups} />
          <Route
            exact
            path="/people-group/view/:_id/:tab?/:reportCategory?"
            component={ViewPeopleGroup}
          />
          <Route
            exact
            path="/holistic-project/view/:projectId/loan-agreement/:loanAgreementId"
            component={ViewLoanAgreementPage}
          />
          <Route
            exact
            path="/holistic-project/view/:_id/:tab?/:reportCategory?"
            component={ViewHolisticProject}
          />
          <Route
            exact
            path="/holistic-project/edit/:_id"
            component={EditHolisticProject}
          />
          <Route
            exact
            path="/holistic-projects"
            component={ListHolisticProjectsPage}
          />
          <Route
            exact
            path="/holistic-proposals"
            component={ListHolisticProjectProposalsPage}
          />
          <Route
            exact
            path="/holistic-proposals/edit/:reportId"
            component={EditProposal}
          />
          <Route
            exact
            path="/holistic-proposals/review/:reportId"
            component={ViewHolisticProposal}
          />
          <Route
            exact
            path="/holistic-quarterly-reports/review/:reportId"
            component={ViewHolisticQuarterlyReport}
          />
          <Route
            exact
            path="/holistic-quarterly-reports/edit/:reportId"
            component={EditHolisticQuarterlyReport}
          />
          <Route
            exact
            path="/holistic-quarterly-reports/:tab"
            component={ListHolisticProjectReportsPage}
          />
          <Route
            exact
            path="/loan-agreement-templates"
            component={ListLoanAgreementTemplatesPage}
          />
          <Route
            exact
            path="/loan-agreement-template/view/:_id"
            component={ViewLoanAgreementTemplate}
          />
          <Route exact path="/mou-templates" component={ListMouTemplatesPage} />
          <Route
            exact
            path="/mou-template/view/:_id"
            component={ViewMouTemplate}
          />
          <Route exact path="/mous" component={ListMousPage} />
          <Route exact path="/mous/view/:_id" component={ViewMouPage} />
          <Route
            exact
            path="/training-event-reports/:reportCategory?"
            component={TrainingEventReportsPage}
          />
          <Route
            exact
            path="/training-event-reports/review/:reportId"
            component={ViewTrainingEventReport}
          />
          <Route
            exact
            path="/training-event-reports/edit/:reportId"
            component={EditTrainingEventReport}
          />
          <Route
            exact
            path="/training-quarterly-reports/:reportCategory?"
            component={TrainingQuarterlyReportsPage}
          />
          <Route
            exact
            path="/training-quarterly-reports/review/:reportId"
            component={ViewTrainingQuarterlyReportPage}
          />
          <Route
            exact
            path="/training-quarterly-reports/edit/:reportId"
            component={EditTrainingQuarterlyReport}
          />
          <Route
            exact
            path="/training-courses"
            component={AllTrainingCoursesPage}
          />
          <Route
            exact
            path="/training-files"
            component={AllTrainingFilesPage}
          />
          <Route
            exact
            path="/training-courses/view/:_id"
            component={ViewTrainingCourse}
          />
          <Route exact path="/pushpay/:tab?" component={PushPayItems} />
          <Route exact path="/payouts" component={PayoutsV2Page} />
          <Route exact path="/accounting/:tab" component={AccountingHomePage} />
          <Route
            exact
            path="/accounting/accounts/view/:_id"
            component={ViewAccountPage}
          />
          <Route
            exact
            path="/accounting/reports/view/:_id"
            component={ViewFinanceReportPage}
          />
          <Route exact path="/adoptions" component={ListAdoptionsPageA10} />
          <Route
            exact
            path="/adoptions/new/:_id"
            component={CreateAdoptionPageA10}
          />
          <Route exact path="/adoption/view/:_id" component={ViewAdoption} />
          <Route
            exact
            path="/payout/edit/:_id/:_tab?"
            component={EditPayoutV2}
          />
          <Route
            exact
            path="/upg-quarterly-reports/review/:reportId"
            component={ViewUpgQuarterlyReport}
          />
          <Route
            exact
            path="/upg-quarterly-reports/edit/:reportId"
            component={EditUpgQuarterlyReport}
          />
          <Route
            exact
            path="/upg-quarterly-reports/:reportCategory"
            component={UpgQuarterlyReportsPage}
          />
          <Route exact path="/edit-profile" component={EditProfile} />
          <Route exact path="/prayer" component={PrayerRequestPage} />
          <Route exact path="/miracles" component={MiraclesPage} />
          <Route
            exact
            path="/adoption-stats"
            component={ListAdoptionStatsPage}
          />
          <Route exact path="/public-forms" component={PublicFormsList} />
          <Route
            exact
            path="/public-forms/:_id/review"
            component={ReviewPublicForm}
          />
          <Route
            exact
            path="/project-sponsorships"
            component={AllProjectSponsorshipsPage}
          />
          <Route
            exact
            path="/project-sponsorship/view/:_id/:tab?"
            component={ViewSponsorshipPage}
          />
          <Route
            exact
            path="/upg-prayer-adoptions"
            component={ListUpgPrayerAdoptionsPage}
          />
          <Route
            exact
            path="/upg-prayer-adoptions/view/:_id"
            component={ViewUpgPrayerAdoptionPage}
          />
          <Route exact path="/settings" component={SettingsPage} />
          <Route
            exact
            path="/cc/auth"
            component={ConstantContactAuthResultPage}
          />
          <Route exact path="/saved-images" component={SavedImagesPage} />
          <Route exact path="/manage-tags" component={ManageTagsPage} />
          <Route
            exact
            path="/manage-languages"
            component={ManageLanguagesPage}
          />
          <Route component={Error404} />
        </Switch>
        {/* </ScrollToTop> */}
        <Footer />
      </Layout>
    </Layout>
  );
}

export default MainRouter;
