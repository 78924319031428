import { displayMoney } from '@aims/shared/shared/utils';
import { ExportOutlined } from '@ant-design/icons';
import { Button, message, notification } from 'antd';
import { ExportToCsv } from 'export-to-csv';
import React, { useCallback, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

function doExport(hpInstallments, locale, title) {
  const data = hpInstallments.map((t) => {
    return {
      _id: t._id,
      amount: displayMoney((t.amount || 0) / 10000),
      dueAt: new Intl.DateTimeFormat(locale, {
        dateStyle: 'medium',
      }).format(new Date(t.dueAt)),
      paidAmount: displayMoney((t.paidAmount || 0) / 10000),
      paidAt: t.paidAt
        ? new Intl.DateTimeFormat(locale, {
            dateStyle: 'medium',
          }).format(new Date(t.paidAt))
        : 'N/A',
      regionCode: t.regionCode,
      notes: t.notes,
    };
  });
  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    title,
    useTextFile: false,
    useBom: true,
    filename: title,
    headers: [
      'Installment ID',
      'Amount',
      'Due On',
      'Paid Amount',
      'Paid On',
      'Region Code',
      'Notes',
    ],
  };

  const csvExporter = new ExportToCsv(options);

  csvExporter.generateCsv(data);
}

function ExportPaymentPlanBtn({ project, hpInstallments }) {
  const [exporting, setExporting] = useState(false);
  const locale = useSelector((store) => store.locale, shallowEqual);

  const handleExportCsv = useCallback(() => {
    message.info('Exporting ...');
    setExporting(true);
    try {
      const title = `Repayment Plan for ${project.projectName}`;
      doExport(hpInstallments, locale, title);
      message.success('Success');
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: 'We apologize.  An error occurred',
      });
    }
    setExporting(false);
  }, [locale, hpInstallments, project]);
  return (
    <Button
      icon={<ExportOutlined />}
      onClick={handleExportCsv}
      loading={exporting}
    >
      Export CSV
    </Button>
  );
}

export default ExportPaymentPlanBtn;
