import sharedSettings from '@aims/shared/sharedSettings';
import { BarChartOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import BalancesReportTotalsSection from '../finance-reports/BalancesReportTotalsSection';
import AccountsTable from './AccountsTable';
import ExportBalancesBtn from './components/ExportBalancesBtn';

const { Title, Paragraph } = Typography;

function BalanceReportingTab({ selected }) {
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [report, setReport] = useState();

  const handleFinish = useCallback(async () => {
    setLoading(true);
    setError(undefined);
    try {
      if (selected.length === 0) {
        throw Error('At least one account must be selected.');
      }
      const total = selected.reduce((prev, curr) => {
        return prev + curr.balance.balance;
      }, 0.0);
      setReport({
        createdAt: new Date().toISOString(),
        accounts: selected.sort((a, b) => a.name.localeCompare(b.name)),
        total,
      });
    } catch (err) {
      console.error(err);
      setError(err.message);
    }
    setLoading(false);
  }, [selected]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          backgroundColor: '#c6cfd3',
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 12,
          paddingBottom: 12,
          alignItems: 'center',
          borderRadius: 8,
          marginBottom: 32,
        }}
      >
        <Title level={5} style={{ margin: 8 }}>
          {`${selected.length} Accounts Selected`}
        </Title>
      </div>
      <div style={{ marginBottom: 32 }}>
        <Button
          icon={<BarChartOutlined />}
          type="primary"
          shape="round"
          style={{ marginLeft: 'auto' }}
          onClick={handleFinish}
          loading={loading}
          disabled={!selected?.length}
        >
          Generate Report
        </Button>
      </div>
      {error && (
        <Paragraph
          type="danger"
          style={{ padding: 32, textAlign: 'center', fontSize: 14 }}
        >
          {error}
        </Paragraph>
      )}
      {!error && !report && (
        <div
          style={{
            padding: 32,
            textAlign: 'center',
            fontSize: 72,
            fontWeight: 600,
            color: sharedSettings.colors.borderGray,
          }}
        >
          Get Ready
        </div>
      )}
      {!error && report && (
        <>
          <Title level={2}>Account Balance Report</Title>
          <BalancesReportTotalsSection report={report} />
          <Title level={2}>Accounts</Title>
          <div style={{ marginBottom: 16, textAlign: 'right' }}>
            <ExportBalancesBtn accounts={report.accounts} />
          </div>
          <AccountsTable accounts={report.accounts} loading={loading} />
        </>
      )}
    </>
  );
}

export default BalanceReportingTab;
