import { displayMoney } from '@aims/shared/shared/utils';
import sharedSettings from '@aims/shared/sharedSettings';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Divider, List, Modal, Skeleton, Typography } from 'antd';
import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AdoptionLevels } from '../../../adoptions/constants';
import { AccountTypesJ25 } from '../../../accounting/accounts-j25/constants';

const { Title, Text, Paragraph } = Typography;

function DisplayField({ title, value, component }) {
  return (
    <List.Item>
      <div>
        <Text
          style={{
            color: sharedSettings.colors.primary,
            fontSize: 14,
            fontWeight: 600,
          }}
        >
          {title}
        </Text>
      </div>
      <div>
        {component ? component : <Text style={{ fontSize: 14 }}>{value}</Text>}
      </div>
    </List.Item>
  );
}

function ViewDonationModalA10({ visible, onCancel, isRecurring }) {
  const { payment } = visible || {};
  const locale = useSelector((store) => store.locale, shallowEqual);
  const adoptionFields = useMemo(() => {
    if (payment && payment.adoptions) {
      return (
        payment &&
        payment.adoptions &&
        payment.adoptions
          .filter((pa) => pa.adoption)
          .map((paymentAdoption) => {
            const adoption = paymentAdoption.adoption;
            return [
              {
                title: 'Allocation Amount',
                component: paymentAdoption.amount && (
                  <Text style={{ fontSize: 18, fontWeight: 600 }}>
                    {displayMoney(paymentAdoption.amount / 1e4)}
                  </Text>
                ),
              },
              {
                title: 'Allocation Percentage',
                component: (
                  <Text
                    style={{ fontSize: 18, fontWeight: 600 }}
                  >{`${Math.round(paymentAdoption.percent * 100)}%`}</Text>
                ),
              },
              {
                title: 'Sponsorship Level',
                value: AdoptionLevels[adoption.level]?.label,
              },
              {
                title: 'Field Worker',
                value: adoption.fieldWorkerName || 'No Field Worker',
              },
              {
                title: 'Regional Coordinator',
                value: adoption.regionalCoordName || 'No Regional Coordinator',
              },
              {
                title: 'People Group ID',
                value: adoption.peopleGroupId,
              },
              {
                title: 'Sponsorship',
                component: (
                  <Link
                    to={`/adoption/view/${paymentAdoption.adoptionId}?from=/pushpay/donations`}
                  >
                    View Sponsorship
                  </Link>
                ),
              },
              {
                title: 'Account',
                component: adoption.accountId && (
                  <Link to={`/accounting/accounts/view/${adoption.accountId}`}>
                    View Account
                  </Link>
                ),
              },
            ].filter((a) => a.value != null || a.component != null);
          })
      );
    }
    return [];
  }, [payment]);

  const holisticFields = useMemo(() => {
    if (payment && payment.sponsorships) {
      return (
        payment &&
        payment.sponsorships &&
        payment.sponsorships
          .filter((pa) => pa.sponsorship)
          .map((paymentSponsorship) => {
            const sponsorship = paymentSponsorship.sponsorship;
            return [
              {
                title: 'Allocation Amount',
                component: paymentSponsorship.amount && (
                  <Text style={{ fontSize: 18, fontWeight: 600 }}>
                    {displayMoney(paymentSponsorship.amount / 1e4)}
                  </Text>
                ),
              },
              {
                title: 'Allocation Percentage',
                component: (
                  <Text
                    style={{ fontSize: 18, fontWeight: 600 }}
                  >{`${Math.round(paymentSponsorship.percent * 100)}%`}</Text>
                ),
              },
              {
                title: 'Sponsor Name',
                value: sponsorship.sponsorName || 'No Sponsor',
              },
              {
                title: 'Holistic Project',
                value: sponsorship.projectName || 'No Holistic Project',
              },
              {
                title: 'Region',
                value: sponsorship.region || 'No Region',
              },
              {
                title: 'Sponsorship',
                component: (
                  <Link
                    to={`/project-sponsorship/view/${paymentSponsorship.sponsorshipId}?from=/pushpay/donations`}
                  >
                    View Sponsorship
                  </Link>
                ),
              },
              {
                title: 'Holistic Project',
                component: sponsorship.projectId && (
                  <Link
                    to={`/holistic-project/view/${sponsorship.projectId}/details?from=/pushpay/donations`}
                  >
                    View Holistic Project
                  </Link>
                ),
              },
            ].filter((a) => a.value != null || a.component != null);
          })
      );
    }
    return [];
  }, [payment]);

  const accountFields = useMemo(() => {
    if (payment && payment.directAccounts) {
      return (
        payment &&
        payment.directAccounts &&
        payment.directAccounts
          .filter((pa) => pa.account)
          .map((paymentAccount) => {
            const account = paymentAccount.account;
            return [
              {
                title: 'Allocation Amount',
                component: paymentAccount.amount && (
                  <Text style={{ fontSize: 18, fontWeight: 600 }}>
                    {displayMoney(paymentAccount.amount / 1e4)}
                  </Text>
                ),
              },
              {
                title: 'Allocation Percentage',
                component: (
                  <Text
                    style={{ fontSize: 18, fontWeight: 600 }}
                  >{`${Math.round(paymentAccount.percent * 100)}%`}</Text>
                ),
              },
              {
                title: 'Account Name',
                value: account.name,
              },
              {
                title: 'Account Type',
                value: AccountTypesJ25[account.type]?.label,
              },
              {
                title: 'Account',
                component: (
                  <Link to={`/accounting/accounts/view/${account._id}`}>
                    View Account
                  </Link>
                ),
              },
            ].filter((a) => a.value != null || a.component != null);
          })
      );
    }
    return [];
  }, [payment]);

  const fields = useMemo(() => {
    if (payment) {
      const dateFormat = new Intl.DateTimeFormat(locale, {
        dateStyle: 'full',
      });
      let notes;
      if (payment.notes) {
        try {
          notes = JSON.parse(payment.notes);
        } catch (err) {
          console.warn('Failed to parse notes');
        }
      }
      return [
        {
          title: 'Donor',
          component: (
            <Text style={{ fontSize: 16, fontWeight: 600 }}>
              {payment.payerName}
            </Text>
          ),
        },
        {
          title: 'Amount',
          component: payment.usdCentsAmount && (
            <Text style={{ fontSize: 18, fontWeight: 600 }}>
              {displayMoney(payment.usdCentsAmount / 100)}
            </Text>
          ),
        },
        !isRecurring && {
          title: 'Given On',
          component: payment.givenOn && (
            <Text style={{ fontSize: 16, fontWeight: 600 }}>
              {dateFormat.format(new Date(payment.givenOn))}
            </Text>
          ),
        },
        !isRecurring && {
          title: 'Received On',
          component: (
            <Text style={{ fontSize: 16, fontWeight: 600 }}>
              {dateFormat.format(new Date(payment.ppReceivedOn))}
            </Text>
          ),
        },
        isRecurring && {
          title: 'Start Date',
          component: (
            <Text style={{ fontSize: 16, fontWeight: 600 }}>
              {dateFormat.format(new Date(payment.startDate))}
            </Text>
          ),
        },
        isRecurring && {
          title: 'Frequency',
          value: payment.frequency,
        },
        {
          title: 'Status',
          value: payment.status,
        },
        {
          title: 'Fund',
          value: payment.fundName,
        },
        {
          title: 'Payment ID',
          value: payment._id,
        },
        !isRecurring && {
          title: 'Transaction ID',
          value: payment.transactionId,
        },
        !isRecurring && {
          title: 'Settlment ID',
          value: payment.settlementId,
        },
        !isRecurring && {
          title: 'Recurring Donation',
          component: payment.recurringPaymentToken && (
            <Link
              to={`/pushpay/recurring?_id=${payment.recurringPaymentToken}`}
              onClick={onCancel}
            >
              {payment.recurringPaymentToken}
            </Link>
          ),
        },
        !isRecurring && {
          title: 'Refund',
          value: payment.refundForId ? 'Yes' : 'No',
        },
        {
          title: 'Catalog',
          value: notes && (notes.t || notes.type) ? 'Yes' : 'No',
        },
      ].filter((a) => a && (a.value != null || a.component != null));
    }
    return [];
  }, [payment, locale, isRecurring, onCancel]);
  return (
    <Modal
      open={visible}
      closable
      destroyOnClose={true}
      maskClosable
      cancelText="Cancel"
      okText="Change Password"
      footer={null}
      onCancel={onCancel}
      width={700}
      styles={{
        body: {
          paddingTop: 32,
        },
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <Title level={2} style={{ marginBottom: 24 }}>
          {isRecurring ? 'Recurring Donation Details' : 'Donation Details'}
        </Title>
        <div>
          {!payment && <Skeleton active paragraph title />}
          <List
            dataSource={fields}
            renderItem={(item) => (
              <DisplayField
                title={item.title}
                value={item.value}
                component={item.component}
              />
            )}
            loading={!payment}
            locale={{
              emptyText: <div />,
            }}
            grid={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 3, xxl: 3, gutter: 16 }}
          />
        </div>
      </div>
      <div style={{ textAlign: 'center', marginTop: 16 }}>
        <Title level={3}>UPG Sponsorship Allocations</Title>
        {adoptionFields.length > 0 ? (
          adoptionFields.map((afs, index) => (
            <div key={index}>
              <Divider>{`UPG Sponsorship ${index + 1}`}</Divider>
              {!payment && <Skeleton active paragraph title />}
              <List
                dataSource={afs}
                renderItem={(item) => (
                  <DisplayField
                    title={item.title}
                    value={item.value}
                    component={item.component}
                  />
                )}
                loading={!payment}
                locale={{
                  emptyText: <div />,
                }}
                grid={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 3, xxl: 3, gutter: 16 }}
              />
            </div>
          ))
        ) : (
          <Paragraph>No UPG Sponsorship Allocations</Paragraph>
        )}
      </div>
      <div style={{ textAlign: 'center', marginTop: 16 }}>
        <Title level={3}>Holistic Project Partnership Allocations</Title>
        {holisticFields.length > 0 ? (
          holisticFields.map((afs, index) => (
            <div key={index}>
              <Divider>{`Holistic Project Partnership ${index + 1}`}</Divider>
              {!payment && <Skeleton active paragraph title />}
              <List
                dataSource={afs}
                renderItem={(item) => (
                  <DisplayField
                    title={item.title}
                    value={item.value}
                    component={item.component}
                  />
                )}
                loading={!payment}
                locale={{
                  emptyText: <div />,
                }}
                grid={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 3, xxl: 3, gutter: 16 }}
              />
            </div>
          ))
        ) : (
          <Paragraph>No Holistic Project Partnership Allocations</Paragraph>
        )}
      </div>
      <div style={{ textAlign: 'center', marginTop: 16 }}>
        <Title level={3}>Direct Accounts</Title>
        {accountFields.length > 0 ? (
          accountFields.map((afs, index) => (
            <div key={index}>
              <Divider>{`Account ${index + 1}`}</Divider>
              {!payment && <Skeleton active paragraph title />}
              <List
                dataSource={afs}
                renderItem={(item) => (
                  <DisplayField
                    title={item.title}
                    value={item.value}
                    component={item.component}
                  />
                )}
                loading={!payment}
                locale={{
                  emptyText: <div />,
                }}
                grid={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 3, xxl: 3, gutter: 16 }}
              />
            </div>
          ))
        ) : (
          <Paragraph>No Direct Accounts</Paragraph>
        )}
      </div>
      <div style={{ textAlign: 'center', marginTop: 32 }}>
        <Button icon={<CloseOutlined />} onClick={onCancel} type="primary">
          Close
        </Button>
      </div>
    </Modal>
  );
}

export default ViewDonationModalA10;
