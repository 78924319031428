import {
  createReportAction,
  deleteReportAction,
  readReportsAction,
  updateReportAction,
} from '@aims/shared/redux-store/reports-store';
import useManyLocalM20 from '@aims/shared/shared/use-many-local-m20';
import useManySomethingsM20 from '@aims/shared/shared/use-many-somethings-m20';
import useSafeStateA10 from '@aims/shared/shared/use-safe-state-a10';
import useSingleSomethingUpdatesA10 from '@aims/shared/shared/use-single-something-updates-a10';
import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';
import { DollarCircleOutlined } from '@ant-design/icons';
import { Button, Space, Table, Tooltip, Typography } from 'antd';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import DeleteThingDropdown from '../../../../../components/DeleteThingDropdown';
import settings from '../../../../../settings';
import AddInstallmentBtn from './AddInstallmentBtn';
import AddPaymentModal from './AddPaymentModal';
import {
  allHpInstallmentsQuery,
  extractAllHpInstallments,
  hpInstallmentUpdatesSubscription,
} from './constants';
import DeleteInstallmentsModal from './DeleteInstallmentsModal';
import getHpInstallmentStatus from './getHpInstallmentStatus';
import LockPaymentPlanBtn from './LockPaymentPlanBtn';
import SelectedInstallmentRows from './SelectedInstallmentRows';
import ViewInstallmentRow from './ViewInstallmentRow';
import DeleteInstallmentPaymentModal from './DeleteInstallmentPaymentModal';
import useProfile from '../../../../../shared/use-profile';
import ExportPaymentPlanBtn from './ExportPaymentPlanBtn';

const { Text } = Typography;

function ViewPaymentPlan({ project }) {
  const filters = useRef({
    projectId: project._id,
  });
  const queryId = `ViewPaymentPlan.${project._id}`;
  const { error } = useSingleSomethingUpdatesA10({
    variables: { filters: filters.current },
    subscription: hpInstallmentUpdatesSubscription,
    createAction: createReportAction,
    updateAction: updateReportAction,
    deleteAction: deleteReportAction,
    extractSomething: (resp) => resp?.data?.hpInstallmentUpdatesForAdmin,
    queryId,
  });
  const [sortedBy, setSortedBy] = useState('dueAt');
  const [sortOrder, setSortOrder] = useState('ASC');
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);
  const { loading } = useManyLocalM20({
    query: allHpInstallmentsQuery,
    extract: extractAllHpInstallments,
    readAction: readReportsAction,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
  });
  const { data: hpInstallments, search: hpInstallmentSearch } =
    useManySomethingsM20({
      filterFunc: (hpInstallment) => {
        if (hpInstallment.projectId !== project._id) {
          return false;
        }
        return true;
      },
      table: 'reports',
      sortByFunc: (a, b) => {
        return a.dueAt?.localeCompare(b.dueAt);
      },
      searchKeys: ['_id', 'notes'],
      queryId,
    });

  const locale = useSelector((store) => store.locale, shallowEqual);
  const dateformat = new Intl.DateTimeFormat(locale, {
    dateStyle: 'long',
  });

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = !project?.paymentPlanLocked
    ? {
        selectedRowKeys,
        onChange: onSelectChange,
      }
    : undefined;

  const [deleting, setDeleting] = useState();
  const onDelete = (records) => setDeleting({ installments: records });
  const onFinishDelete = () => {
    setDeleting(undefined);
    setSelectedRowKeys([]);
  };
  const onCancelDelete = () => setDeleting(undefined);

  const uninstalled = useMemo(() => {
    if (hpInstallments) {
      const installed = hpInstallments.reduce((prev, curr) => {
        return prev + curr.amount;
      }, 0);
      return getNumberValue(project.paybackTotalX4) - installed;
    }
    return getNumberValue(project.paybackTotalX4);
  }, [project, hpInstallments]);

  const [expanded, setExpanded] = useState([]);
  const [rowRefetches, setRowRefetches, _rowRefetches] = useSafeStateA10({});
  const setRowRefetch = useCallback(
    (hpInstallmentId, refetch) => {
      setRowRefetches({ ..._rowRefetches.current, [hpInstallmentId]: refetch });
    },
    [_rowRefetches, setRowRefetches],
  );

  const [paying, setPaying] = useState();
  const onPay = (record) => setPaying({ hpInstallment: record });
  const onFinishPay = () => {
    Object.entries(rowRefetches).map(([_, refetch]) => refetch());
    setPaying(undefined);
  };
  const onCancelPay = () => setPaying(undefined);

  const [deletingPayment, setDeletingPayment] = useState();
  const onDeletePayment = (payment) => setDeletingPayment({ payment });
  const onFinishDeletePayment = () => {
    Object.entries(rowRefetches).map(([_, refetch]) => refetch());
    setDeletingPayment(undefined);
  };
  const onCancelDeletePayment = () => setDeletingPayment(undefined);

  const profile = useProfile();
  const permissions = profile?.permissions;
  const hasCreatePaymentPermission = useMemo(() => {
    return permissions?.find((p) => p.prefix === 'pay-admin-hp-installment');
  }, [permissions]);
  const hasCreateInstallmentPermission = useMemo(() => {
    return permissions?.find((p) => p.prefix === 'create-admin-hp-installment');
  }, [permissions]);

  return (
    <>
      <div style={{ display: 'flex', marginBottom: 16 }}>
        <div style={{ flex: 1 }} />
        {hasCreateInstallmentPermission && (
          <AddInstallmentBtn project={project} uninstalled={uninstalled} />
        )}
        <ExportPaymentPlanBtn
          project={project}
          hpInstallments={hpInstallments}
        />
      </div>
      <SelectedInstallmentRows
        selectedKeys={selectedRowKeys}
        installments={hpInstallments}
        onDelete={onDelete}
      />
      <Table
        dataSource={hpInstallments}
        loading={!hpInstallments.length && loading}
        pagination={false}
        style={{ width: '100%' }}
        rowKey="_id"
        scroll={{ x: 1200 }}
        rowSelection={rowSelection}
        expandable={{
          expandedRowKeys: expanded,
          onExpandedRowsChange: (rows) => {
            setExpanded(rows);
          },
          expandedRowRender: (record) => (
            <ViewInstallmentRow
              setRefetch={setRowRefetch}
              hpInstallment={record}
              onDeletePayment={onDeletePayment}
              hasCreatePaymentPermission={hasCreatePaymentPermission}
              hasCreateInstallmentPermission={hasCreateInstallmentPermission}
            />
          ),
          rowExpandable: (record) => true,
        }}
      >
        <Table.Column
          title="Due On"
          dataIndex="dueAt"
          render={(text, record) => {
            return dateformat.format(new Date(record.dueAt));
          }}
        />
        <Table.Column
          title="Amount"
          dataIndex="amount"
          render={(text, record) => {
            if (record.amount) {
              return displayMoney(record.amount / 10000);
            }
            return null;
          }}
        />
        <Table.Column
          title="Paid On"
          dataIndex="paidOn"
          render={(text, record) => {
            return record.paidAt && dateformat.format(new Date(record.paidAt));
          }}
        />
        <Table.Column
          title="Paid Amount"
          dataIndex="paidAmount"
          render={(text, record) => {
            if (record.paidAmount) {
              return displayMoney(record.paidAmount / 10000);
            }
            return null;
          }}
        />
        <Table.Column
          title="Status"
          dataIndex="status"
          key="status"
          render={(text, record) => {
            const status = getHpInstallmentStatus(record);
            return (
              <Text style={{ fontWeight: 600, color: status.color }}>
                {status.label}
              </Text>
            );
          }}
        />
        <Table.Column
          title="Action"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              {project.payoutAccountId &&
                project.payoutAccountId &&
                hasCreatePaymentPermission && (
                  <Tooltip title="Add Payment">
                    <Button
                      onClick={() => onPay(record)}
                      icon={<DollarCircleOutlined />}
                    />
                  </Tooltip>
                )}
              {!project?.paymentPlanLocked &&
                hasCreateInstallmentPermission && (
                  <DeleteThingDropdown
                    thing={record}
                    label="Delete Installment"
                    handleDelete={(thing) => onDelete([thing])}
                    btnProps={{ type: 'default' }}
                  />
                )}
            </Space>
          )}
        />
      </Table>
      <LockPaymentPlanBtn project={project} />
      <DeleteInstallmentsModal
        visible={deleting}
        onCancel={onCancelDelete}
        onFinish={onFinishDelete}
      />
      <AddPaymentModal
        visible={paying}
        onCancel={onCancelPay}
        onFinish={onFinishPay}
      />
      <DeleteInstallmentPaymentModal
        visible={deletingPayment}
        onCancel={onCancelDeletePayment}
        onFinish={onFinishDeletePayment}
      />
    </>
  );
}

export default ViewPaymentPlan;
