import sharedSettings from '@aims/shared/sharedSettings';
import { EditOutlined } from '@ant-design/icons';
import { Button, Form, Typography } from 'antd';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

const { Title, Paragraph, Text } = Typography;

function EditableOneLineElement({
  name,
  label,
  noLabel,
  text,
  initialValues,
  beforeSave,
  handleSave,
  parties,
  attachments,
  InputElement,
  getDisplayText,
  readOnly,
  inputProps,
}) {
  const [editing, setEditing] = useState();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState();
  const doSave = useCallback(
    async (values) => {
      setLoading(true);
      if (beforeSave) {
        handleSave(beforeSave(values));
      } else {
        handleSave(values);
      }
      setEditing(false);
      setLoading(false);
    },
    [handleSave, beforeSave],
  );

  const ivs = useRef();
  useEffect(() => {
    if (ivs.current !== JSON.stringify(initialValues)) {
      ivs.current = JSON.stringify(initialValues);
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  const displayText = useMemo(() => {
    if (text && getDisplayText) {
      return getDisplayText(text);
    }
    if (text) {
      let edited = text;
      const refs = text.matchAll(/{{(.*)}}/g);
      let ref = refs.next();
      while (!ref.done) {
        const party = parties && parties.find((a) => a.ref === ref.value[1]);
        if (party) {
          edited = text.replace(ref.value[0], `<Party: ${party.name}>`);
        }
        const attachment =
          attachments && attachments.find((a) => a.ref === ref.value[1]);
        if (attachment) {
          edited = text.replace(ref.value[0], `Attachment: ${attachment.name}`);
        }
        ref = refs.next();
      }
      return edited;
    }
    return noLabel;
  }, [text, noLabel, attachments, parties, getDisplayText]);

  return (
    <>
      {!editing && (
        <div className="line-heading">
          <div style={{ marginRight: 32 }}>
            <div>
              <Text
                style={{
                  marginRight: 32,
                  color: sharedSettings.colors.primary,
                }}
              >{`${label}:`}</Text>
            </div>
            <div>
              <Text>{displayText}</Text>
            </div>
          </div>
          {!readOnly && !editing && (
            <Button
              onClick={() => setEditing(true)}
              icon={<EditOutlined />}
              type="text"
            >
              Edit
            </Button>
          )}
        </div>
      )}
      {editing && (
        <Form
          id={name}
          form={form}
          layout="inline"
          onFinish={doSave}
          style={{ paddingTop: 8, paddingBottom: 8 }}
        >
          <Form.Item name={name}>
            <InputElement disabled={loading} {...inputProps} />
          </Form.Item>
          <Form.Item>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                htmlType="button"
                onClick={() => setEditing(false)}
                style={{ marginRight: 16 }}
              >
                Cancel
              </Button>
              <Button htmlType="submit" type="primary">
                Save
              </Button>
            </div>
          </Form.Item>
        </Form>
      )}
      <style jsx global>{`
        .line-answer > .line-answer-p:last-child {
          margin-bottom: 0px;
        }
      `}</style>
      <style jsx>{`
        .line-heading {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 8px;
        }
        .line-answer {
          text-align: center;
          background-color: ${sharedSettings.colors.borderGray};
          border-radius: 8px;
          padding: 12px;
          font-size: 14px;
          margin-bottom: 24px;
        }
      `}</style>
    </>
  );
}

export default EditableOneLineElement;
