import { HolisticProjectStages } from '@aims/shared/holistic-projects/constants';
import { CheckOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { Button, Form, Input, Modal, notification, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { generateUuid } from '../../../shared/utils';
import SelectContact from '../../contacts/SelectContact';
import { createHolisticProjectMutation } from '../../holistic-projects/constants';
import SelectRegionForAdmin from '../../people_groups/ListPeopleGroups/SelectRegionForAdmin';
import {
  reviewHolisticProposalMutation,
  updateHolisticProposalForAdminMutation,
} from '../constants';
import { displayMoney } from '@aims/shared/shared/utils';

const { Title, Paragraph } = Typography;

export const createHolisticProjectFromProposalMutation = gql`
  mutation CreateHolisticProjectFromProposal(
    $proposalId: ID!
    $projectDesc: String!
    $regionalCoordId: ID!
  ) {
    createHolisticProjectFromProposal(
      proposalId: $proposalId
      projectDesc: $projectDesc
      regionalCoordId: $regionalCoordId
    )
  }
`;

const ApproveHolisticProjectProposalBtn = ({ report, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [showingModal, setShowingModal] = useState(null);
  const [reviewHolisticProjectRequest] = useMutation(
    reviewHolisticProposalMutation,
  );
  const [updateReport] = useMutation(updateHolisticProposalForAdminMutation);
  const [createHolisticProject] = useMutation(
    createHolisticProjectFromProposalMutation,
  );

  const handleFinish = useCallback(
    async (values) => {
      setLoading(true);
      try {
        await reviewHolisticProjectRequest({
          variables: {
            reportId: report._id,
            status: 'APPROVED',
          },
        });
        notification.success({
          message: 'Saved',
          description: 'Proposal Approved',
        });
        const { contactId, regionalCoordId, projectDesc, regionCode } = values;
        const holisticProjectId = generateUuid();
        {
          await updateReport({
            variables: {
              report: {
                _id: report._id,
                contactId: contactId,
                reportType: report.reportType,
                holisticProjectId,
                onBehalfOfId: contactId,
                regionCode: regionCode,
              },
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Proposal updated successfully',
          });
        }
        // Create holistic project
        {
          await createHolisticProject({
            variables: {
              proposalId: report._id,
              projectDesc,
              regionalCoordId,
            },
          });
          notification.success({
            message: 'Saved',
            description: 'Holistic project created successfully',
          });
        }
        setShowingModal(null);
        if (onSuccess) {
          onSuccess();
        }
      } catch (err) {
        console.log(err);
        notification.error({
          message: 'Error',
          description: 'We apologize.  An error occurred.',
        });
      }
      setLoading(false);
    },
    [
      reviewHolisticProjectRequest,
      report,
      onSuccess,
      updateReport,
      createHolisticProject,
    ],
  );

  return (
    <>
      <Button
        loading={loading}
        icon={<CheckOutlined />}
        onClick={() => setShowingModal(true)}
      >
        Approve Proposal
      </Button>

      <Modal
        header={null}
        footer={null}
        open={showingModal}
        closable={!loading}
        destroyOnClose={true}
        maskClosable={!loading}
        onCancel={() => {
          setShowingModal(false);
        }}
        styles={{ body: { paddingTop: 16 } }}
      >
        <Title level={3} style={{ textAlign: 'center', marginBottom: 16 }}>
          Approve Proposal
        </Title>
        <Paragraph style={{ marginBottom: 0, textAlign: 'center' }}>
          Loan Amount
        </Paragraph>
        <Paragraph
          style={{ fontWeight: 600, fontSize: 18, textAlign: 'center' }}
        >
          {displayMoney(report.loanTotalUsd)}
        </Paragraph>
        <Form onFinish={handleFinish} layout="vertical">
          <Form.Item
            label="Project's Public Description"
            name="projectDesc"
            rules={[{ required: true, message: 'Please enter a description' }]}
            initialValue={report?.desc}
          >
            <Input.TextArea disabled={loading} rows={4} />
          </Form.Item>
          <Form.Item
            label="Select this holistic project's region"
            name="regionCode"
            rules={[{ required: true, message: 'Please select a region' }]}
            initialValue={report?.regionCode}
          >
            <SelectRegionForAdmin disabled={loading} />
          </Form.Item>
          <Form.Item
            style={{ marginTop: 32 }}
            label="Please select the contact that submitted this proposal"
            name="contactId"
            rules={[{ required: true, message: 'Please select a contact' }]}
            initialValue={report && report.contactId}
          >
            <SelectContact disabled={loading} filters={{ isFWOrRC: true }} />
          </Form.Item>
          <Form.Item
            label="Select a Project Coordinator"
            name="regionalCoordId"
            rules={[{ required: true, message: 'Please select a coordinator' }]}
            initialValue={report && report.contactId}
          >
            <SelectContact
              disabled={loading}
              filters={{ isRegionalCoord: true }}
            />
          </Form.Item>
          <Form.Item>
            <Paragraph style={{ textAlign: 'center' }}>
              {`Are you sure you want to approve this holistic project proposal?  The submitter will be informed that his proposal has been approved and a holistic project will be created.`}
            </Paragraph>
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <div
              style={{
                marginRight: -8,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={() => setShowingModal(null)}
                htmlType="button"
                disabled={loading}
                style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
              >
                Cancel
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                loading={loading}
                style={{ marginLeft: 8, marginRight: 8, marginBottom: 8 }}
              >
                Approve Proposal
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ApproveHolisticProjectProposalBtn;
