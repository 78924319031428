import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

function useSubmittedBy(report) {
  const locale = useSelector((store) => store.locale, shallowEqual);
  const _submittedBy = useMemo(() => {
    if (report && report.contact) {
      if (!report.isSubmitted && report.createdByUser) {
        if (report && report.onBehalfOfId) {
          return `Created by ${report.createdByUser.name} on behalf of ${
            report.contact.fullName
          } on ${new Intl.DateTimeFormat(locale, {
            dateStyle: 'long',
          }).format(new Date(report.createdAt))}`;
        } else {
          return `Created by ${
            report.createdByUser.name
          } on ${new Intl.DateTimeFormat(locale, {
            dateStyle: 'long',
          }).format(new Date(report.createdAt))}`;
        }
      }
      if (report.isSubmitted && report.submittedByUser) {
        if (report && report.onBehalfOfId) {
          return `Submitted by ${report.submittedByUser.name} on behalf of ${
            report.contact.fullName
          } on ${new Intl.DateTimeFormat(locale, {
            dateStyle: 'long',
          }).format(new Date(report.submittedAt))}`;
        } else {
          return `Submitted by ${
            report.submittedByUser.name
          } on ${new Intl.DateTimeFormat(locale, {
            dateStyle: 'long',
          }).format(new Date(report.submittedAt))}`;
        }
      }
    }
    if (report.isSubmitted) {
      if (report && report.createdByUser && report && report.onBehalfOfName) {
        return `Submitted by ${report.createdByUser.name} on behalf of ${
          report.onBehalfOfName
        } on ${new Intl.DateTimeFormat(locale, {
          dateStyle: 'long',
        }).format(new Date(report.submittedAt))}`;
      }
      if (report && report.createdByUser) {
        return `Submitted by ${
          report.createdByUser.name
        } on ${new Intl.DateTimeFormat(locale, {
          dateStyle: 'long',
        }).format(new Date(report.submittedAt))}`;
      }
    }
    if (report && report.createdByUser && report && report.onBehalfOfName) {
      return `Created by ${report.createdByUser.name} on behalf of ${
        report.onBehalfOfName
      } on ${new Intl.DateTimeFormat(locale, {
        dateStyle: 'long',
      }).format(new Date(report.createdAt))}`;
    }
    if (report && report.createdByUser) {
      return `Created by ${
        report.createdByUser.name
      } on ${new Intl.DateTimeFormat(locale, {
        dateStyle: 'long',
      }).format(new Date(report.createdAt))}`;
    }
    return null;
  }, [report, locale]);
  return _submittedBy;
}

export default useSubmittedBy;
