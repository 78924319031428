import { displayMoney } from '@aims/shared/shared/utils';
import sharedSettings from '@aims/shared/sharedSettings';
import { Typography } from 'antd';
import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

const { Title, Text, Paragraph } = Typography;

function BalancesReportTotalsSection({ report }) {
  const locale = useSelector((store) => store.locale, shallowEqual);
  const date = useMemo(() => {
    if (report?.createdAt) {
      return new Intl.DateTimeFormat(locale, {
        dateStyle: 'medium',
      }).format(new Date(report.createdAt));
    }
    return null;
  }, [report, locale]);
  const total = useMemo(() => {
    if (report?.total) {
      return displayMoney(report.total / 10000);
    }
    return '$0.00';
  }, [report]);
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        paddingLeft: '10vw',
        paddingRight: '10vw',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          textAlign: 'center',
          marginLeft: 16,
          marginRight: 16,
          flex: 1,
        }}
      >
        <div>
          <Text
            style={{
              fontSize: 14,
              color: sharedSettings.colors.textGray,
            }}
          >
            Report Date
          </Text>
        </div>
        <Title level={3}>{date}</Title>
      </div>
      <div
        style={{
          textAlign: 'center',
          marginLeft: 16,
          marginRight: 16,
          flex: 1,
        }}
      >
        <div>
          <Text
            style={{
              fontSize: 18,
              color: sharedSettings.colors.textGray,
            }}
          >
            Total
          </Text>
        </div>
        <Title>{total}</Title>
      </div>
    </div>
  );
}

export default BalancesReportTotalsSection;
