import {
  reportFullAttributes,
  reportShortWithDetailsAttributes,
} from '../constants';
import { gql } from '@apollo/client';

export const allTrainingEventReportsQuery = gql`
  query allTrainingEventReports($first: Int, $after: String, $filters: ReportFilters) {
    allTrainingEventReports(first: $first, after: $after, filters: $filters) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${reportShortWithDetailsAttributes}
      }
    }
  }
`;

export const trainingEventReportQuery = gql`
  query TrainingEventReport($reportId: ID!) {
    trainingEventReport(reportId: $reportId) ${reportFullAttributes}
  }
`;

export const createTrainingEventReportMutation = gql`
  mutation CreateTrainingEventReport($report: ReportCreateInput!) {
    createTrainingEventReport(report: $report) {
      report ${reportFullAttributes}
    }
  }
`;

export const updateTrainingEventReportMutation = gql`
  mutation UpdateTrainingEventReport($report: ReportUpdateInput!) {
    updateTrainingEventReport(report: $report) {
      report ${reportFullAttributes}
    }
  }
`;

export const submitTrainingEventReportMutation = gql`
  mutation SubmitTrainingEventReport($reportId: ID!) {
    submitTrainingEventReport(reportId: $reportId) {
      report ${reportFullAttributes}
    }
  }
`;

export const unsubmitTrainingEventReportMutation = gql`
  mutation UnsubmitTrainingEventReport($reportId: ID!) {
    unsubmitTrainingEventReport(reportId: $reportId) {
      report ${reportFullAttributes}
    }
  }
`;

export const deleteTrainingEventReportMutation = gql`
  mutation DeleteTrainingEventReport($reportId: ID!) {
    deleteTrainingEventReport(reportId: $reportId) {
      deletedId
    }
  }
`;

export const ItsmModules = {
  intro: {
    key: 'intro',
    label: 'Introduction',
    sort: 0,
  },
  mod1: {
    key: 'mod1',
    label: 'The Master Plan',
    sort: 1,
  },
  mod2: {
    key: 'mod2',
    label: 'The Mission',
    sort: 2,
  },
  mod3: {
    key: 'mod3',
    label: 'State of the World',
    sort: 3,
  },
  mod4: {
    key: 'mod4',
    label: 'Personalizing the Great Commission',
    sort: 4,
  },
  mod5: {
    key: 'mod5',
    label: 'Final Focus',
    sort: 5,
  },
  mod6: {
    key: 'mod6',
    label: 'Church Planting Movements',
    sort: 6,
  },
  mod7: {
    key: 'mod7',
    label: 'Networking through Partnerships',
    sort: 7,
  },
  mod8: {
    key: 'mod8',
    label: 'Goers and Senders',
    sort: 8,
  },
  mod9: {
    key: 'mod9',
    label: 'Faith Promise Giving',
    sort: 9,
  },
};

export const PotentalTrainerRoles = {
  FIELD_WORKER: {
    key: 'FIELD_WORKER',
    label: 'Potential Field Worker',
  },
  REGIONAL_COORD: {
    key: 'REGIONAL_COORD',
    label: 'Potential Coordinator',
  },
  TRAINER: {
    key: 'TRAINER',
    label: 'Potential Trainer',
  },
};
