import { Typography } from 'antd';
import React from 'react';
import { HolisticQuarterlyReportQuestions } from './questions';

const { Title, Text, Paragraph } = Typography;

function ViewHolisticQuarterlyReport({ report, holisticProject, colors = {} }) {
  return (
    <div className="container">
      <HolisticQuarterlyReportQuestions.title.Display
        colors={colors}
        report={report}
      />
      <HolisticQuarterlyReportQuestions.submittedBy.Display
        colors={colors}
        report={report}
      />
      <HolisticQuarterlyReportQuestions.approvedBy.Display
        colors={colors}
        report={report}
      />
      <div style={{ height: 16 }} />
      <HolisticQuarterlyReportQuestions.projectState.Display
        colors={colors}
        report={report}
      />
      <HolisticQuarterlyReportQuestions.projectAnalysis.Display
        colors={colors}
        report={report}
      />
      <HolisticQuarterlyReportQuestions.projectAnalysisSummary.Display
        colors={colors}
        report={report}
        holisticProject={holisticProject}
      />

      <HolisticQuarterlyReportQuestions.progressDesc.Display
        colors={colors}
        report={report}
      />
      <HolisticQuarterlyReportQuestions.miracles.Display
        colors={colors}
        report={report}
      />
      <HolisticQuarterlyReportQuestions.prayerRequests.Display
        colors={colors}
        report={report}
      />
      <HolisticQuarterlyReportQuestions.publicFiles.Display
        colors={colors}
        report={report}
      />
      <HolisticQuarterlyReportQuestions.privateFiles.Display
        colors={colors}
        report={report}
      />
      <HolisticQuarterlyReportQuestions.notes.Display
        colors={colors}
        report={report}
      />
      <style jsx>{``}</style>
    </div>
  );
}

export default ViewHolisticQuarterlyReport;
