import { gql } from '@apollo/client';

export const AdopterEmailStatuses = {
  NOT_SENT: {
    key: 'NOT_SENT',
    label: 'Not Sent',
  },
  SENDING: {
    key: 'SENDING',
    label: 'Sending',
  },
  SENT: {
    key: 'SENT',
    label: 'Sent',
  },
};

export const upgStatsRecordListAttributes = gql`
  {
    _id
    peopleGroupId
    regionCode
    nameAcrossCountries
    country
    quarter
    numHolisticProject
    numActiveAdoptions
    numAdopters
    numRegionalCoords
    numFieldWorkers
    numChurchesPlanted
    numHeardForFirstTime
    numSalvations
    numWaterBaptisms
    numMiracles
    numPrayerRequests
    totalForFieldWorker
    totalForHolistic
    totalReceived
    adopterEmailStatus
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
`;

export const allUpgStatsRecordsForAdminQuery = gql`
  query AllUpgStatsRecordsForAdmin($first: Int, $after: String, $filters: UpgStatsRecordFiltersForAdmin, $sortBy: [SortBy]) {
    allUpgStatsRecordsForAdmin(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${upgStatsRecordListAttributes}
      }
    }
  }
`;

export const extractAllUpgStatsRecordsForAdmin = (data) =>
  data.allUpgStatsRecordsForAdmin;

export const sendAdoptionEmailMutation = gql`
  mutation SendAdoptionEmail($_id: ID!) {
    sendAdoptionEmail(_id: $_id)
  }
`;

export const deleteUpgStatsRecordMutation = gql`
  mutation DeleteUpgStatsRecord($_id: ID!) {
    deleteUpgStatsRecord(_id: $_id) {
      deletedId
    }
  }
`;
