import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import { ArrowLeftOutlined, UpOutlined } from '@ant-design/icons';
import { BackTop, Button, Skeleton, Space, Typography } from 'antd';
import React, { useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import CSPage from '../../../../../components/CSPage';
import CSPageHeader from '../../../../../components/CSPageHeader';
import { updateLoanAgreementAction } from '../../../../../redux-store/loan-agreements-store';
import useQueryParams from '../../../../../shared/use-query-params';
import ViewLoanAgreement from '../../../../loan-agreements/ViewLoanAgreement';
import {
  extractLoanAgreement,
  projectLoanAgreementQuery,
} from '../../../../loan-agreements/constants';
import { SignatureFontsStyle } from '../../../../loan-agreements/signature-fonts';

const { Text } = Typography;

function ViewLoanAgreementPage() {
  const history = useHistory();
  const [error, setError] = useState(null);

  const { projectId, loanAgreementId } = useParams();
  const queryId = 'view-loan-agreement';

  const { loading, refetch } = useOneM1({
    variables: { _id: loanAgreementId },
    query: projectLoanAgreementQuery,
    extract: extractLoanAgreement,
    queryId,
    fetchPolicy: 'network-only',
    updateAction: updateLoanAgreementAction,
    skip: !loanAgreementId,
    showError: false,
  });
  const loanAgreement = useSingleSomethingA10(
    'loanAgreements',
    loanAgreementId,
    queryId,
  );

  const query = useQueryParams();
  const backTo = useMemo(() => {
    if (query && query.from) {
      return query.from;
    } else {
      return `/holistic-project/view/${projectId}/loan-agreement`;
    }
  }, [query, projectId]);

  return (
    <>
      <CSPage
        containerStyle={{ width: '100%', maxWidth: 'unset' }}
        title="Holistic Project Loan Agreement"
      >
        <CSPageHeader
          titleComponent={null}
          backActions={[
            <Link to={backTo} key="back">
              <Button type="text" icon={<ArrowLeftOutlined />}>
                Back
              </Button>
            </Link>,
          ]}
        />
        {error && (
          <div>
            <Text type="danger">{error}</Text>
          </div>
        )}
        <div className="container">
          {loanAgreement && (
            <ViewLoanAgreement
              loanAgreement={loanAgreement}
              refetch={refetch}
              queryId={queryId}
              backTo={backTo}
            />
          )}
          {loading && (
            <Space direction="vertical">
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </Space>
          )}
        </div>
        <BackTop>
          <Button shape="circle" icon={<UpOutlined />} />
        </BackTop>
      </CSPage>
      <SignatureFontsStyle />
      <style jsx>{`
        .delete-box {
          display: flex;
          justify-content: center;
        }
        .container {
          max-width: 800px;
          margin-left: auto;
          margin-right: auto;
        }
        @media print {
          .container {
            max-width: unset;
            margin-left: unset;
            margin-right: unset;
          }
        }
      `}</style>
      <style jsx global>{`
        @media print {
          * {
            visibility: hidden;
          }
          #loanAgreement {
            visibility: visible;
            box-shadow: none !important;
          }
          #loanAgreement * {
            visibility: visible;
          }
          img {
            visibility: visible;
          }
          aside.ant-layout-sider.ant-layout-sider-light.ant-layout-sider-collapsed.ant-layout-sider-has-trigger.main-nav-menu-slider {
            display: none;
          }
          section > div.page {
            padding: 0px !important;
          }
          section > div.page > div.container > div.header {
            display: none;
          }
        }
      `}</style>
    </>
  );
}

export default ViewLoanAgreementPage;
