import { EditOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { Button, Modal, notification } from 'antd';
import React, { useCallback, useState } from 'react';

export const updateAccountMutation = gql`
  mutation EditThingAccountModal($account: AccountUpdateInput!) {
    updateAccount(account: $account) {
      account {
        _id
      }
    }
  }
`;

function EditThingAccountModal({ account, children, btnProps, btnText }) {
  const [editing, setEditing] = useState();
  const handleEdit = useCallback(() => setEditing(true), []);
  const onDone = useCallback(() => setEditing(false), []);
  const onCancel = useCallback(() => setEditing(false), []);

  const [saving, setSaving] = useState(false);
  const [updateAccount] = useMutation(updateAccountMutation);

  const onFinish = useCallback(
    async (values) => {
      setSaving(true);
      try {
        await updateAccount({
          variables: {
            account: {
              _id: account._id,
              ...values,
            },
          },
        });
        notification.success({
          message: 'Success',
          description: 'Account saved successfully',
        });
        onDone();
      } catch (err) {
        console.error(err);
        notification.error({
          message: 'Error',
          description: 'There was an error updating your Account',
        });
      }
      setSaving(false);
    },
    [account, updateAccount, onDone],
  );
  return (
    <>
      <Modal
        open={editing}
        closable={false}
        destroyOnClose={true}
        maskClosable
        cancelText="Cancel"
        footer={null}
        onCancel={onCancel}
        // width={700}
        styles={{
          body: {
            paddingTop: 16,
          },
        }}
      >
        {React.cloneElement(children, {
          account,
          onFinish,
          saving,
          onCancel,
        })}
      </Modal>
      <Button
        type="text"
        icon={<EditOutlined />}
        onClick={handleEdit}
        {...btnProps}
      >
        {btnText}
      </Button>
    </>
  );
}

export default EditThingAccountModal;
