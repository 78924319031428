import { BankOutlined } from '@ant-design/icons';
import { Tabs, Typography } from 'antd';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import CSPage from '../../../components/CSPage';
import CSPageHeader from '../../../components/CSPageHeader';
import AccountsTab from './AccountsTab';
import TransferReportingTab from './TransferReportingTab';
import TransfersTab from './TransfersTab';
import BalanceReportingTab from './BalanceReportingTab';
import FinanceReportsTab from '../finance-reports/FinanceReportsTab';

const { Title } = Typography;

const titles = {
  accounts: 'Accounts',
  transfers: 'Transactions',
  reporting: 'Reporting',
};

function AccountingHomePage() {
  const history = useHistory();
  const { tab } = useParams();
  const [selected, setSelected] = useState([]);
  return (
    <CSPage containerStyle={{ maxWidth: 'unset' }} title="Accounting">
      <CSPageHeader
        titleComponent={
          <Title>
            <BankOutlined style={{ marginRight: 16 }} />
            {titles[tab] || 'Accounting'}
          </Title>
        }
        style={{ marginBottom: 16 }}
      />
      <Tabs
        defaultActiveKey="accounts"
        activeKey={tab}
        onChange={(key) => history.push(`/accounting/${key}`)}
      >
        <Tabs.TabPane tab="Accounts" key="accounts">
          <AccountsTab selected={selected} setSelected={setSelected} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Transactions" key="transfers">
          <TransfersTab />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Transaction Reporting" key="transfer-reporting">
          <TransferReportingTab selected={selected} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Balance Reporting" key="balance-reporting">
          <BalanceReportingTab selected={selected} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Saved Reports" key="reports">
          <FinanceReportsTab />
        </Tabs.TabPane>
      </Tabs>
      <div style={{ minHeight: 300 }} />
    </CSPage>
  );
}

export default AccountingHomePage;
