import {
  FilterOutlined,
  RedoOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Button, Form, Input, Radio } from 'antd';
import React, { useCallback, useRef, useState } from 'react';
import { notNullOrUndefined } from '../../../shared/utils';
import SelectRegionForAdmin from '../../people_groups/ListPeopleGroups/SelectRegionForAdmin';
import SelectFund from '../../pushpay/funds/SelectFund';
import SelectAccountType from './components/SelectAccountType';

function AccountFilters({
  filtersRef,
  refetch,
  search,
  sortByRef,
  parentFilters,
  sortedBy,
  sortOrder,
  more,
}) {
  const [showFilters, setShowFilters] = useState(false);
  const filterChangeTimeout = useRef();

  const onFiltersChanged = useCallback(
    (_changed, values) => {
      const { search: searchTerm, ...changed } = _changed;
      if (searchTerm != undefined) {
        sortByRef.current = [
          { key: '_score', order: 'DESC' },
          {
            key: sortedBy,
            order: sortOrder,
          },
        ];
        search(searchTerm.toLowerCase());
      } else {
        filtersRef.current = {
          ...filtersRef.current,
          ...Object.entries(changed).reduce((prev, [k, v]) => {
            prev[k] = notNullOrUndefined(v) ? v : undefined;
            return prev;
          }, {}),
          ...parentFilters,
        };
        sortByRef.current = [
          {
            key: sortedBy,
            order: sortOrder,
          },
          { key: '_score', order: 'DESC' },
        ];
        clearTimeout(filterChangeTimeout.current);
        filterChangeTimeout.current = setTimeout(() => {
          refetch();
        }, 500);
      }
    },
    [
      search,
      refetch,
      sortedBy,
      sortOrder,
      filtersRef,
      sortByRef,
      parentFilters,
    ],
  );

  return (
    <>
      <Form
        layout="vertical"
        style={{ marginBottom: 16 }}
        onValuesChange={onFiltersChanged}
      >
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            marginBottom: 8,
          }}
        >
          <Form.Item name="search" style={{ maxWidth: 500, minWidth: 300 }}>
            <Input placeholder="Search" suffix={<SearchOutlined />} />
          </Form.Item>
          <div style={{ flex: 1 }} />
          {more}
          <Button icon={<RedoOutlined />} onClick={refetch}>
            Refresh
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            marginBottom: 16,
          }}
        >
          <Form.Item
            name="external"
            initialValue={false}
            style={{ marginRight: 16 }}
          >
            <Radio.Group buttonStyle="solid">
              <Radio.Button value={false}>Internal</Radio.Button>
              <Radio.Button value={true}>External</Radio.Button>
            </Radio.Group>
          </Form.Item>
          {showFilters && (
            <Button
              icon={<FilterOutlined />}
              onClick={() => {
                setShowFilters(false);
              }}
            >
              Hide Filters
            </Button>
          )}
          {!showFilters && (
            <Button
              icon={<FilterOutlined />}
              onClick={() => setShowFilters(true)}
            >
              Show Filters
            </Button>
          )}
        </div>
        {showFilters && (
          <>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Form.Item
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.external !== curValues.external
                }
                noStyle
              >
                {({ getFieldValue }) => (
                  <Form.Item
                    label="Account Type"
                    name="type"
                    style={{ minWidth: 300, marginRight: 16 }}
                  >
                    <SelectAccountType
                      filterFunc={(a) => {
                        return a.external === getFieldValue('external');
                      }}
                      placeholder="Select an Account Type"
                      allowClear
                      mode="multiple"
                    />
                  </Form.Item>
                )}
              </Form.Item>
              <Form.Item
                label="Fund"
                name="fundId"
                style={{ marginRight: 16, minWidth: 200 }}
              >
                <SelectFund allowClear />
              </Form.Item>
              <Form.Item
                label="Region"
                name="regionCode"
                style={{ minWidth: 200, marginRight: 16 }}
              >
                <SelectRegionForAdmin allowClear />
              </Form.Item>
            </div>
          </>
        )}
      </Form>
    </>
  );
}

export default AccountFilters;
