import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import useSingleSomethingUpdatesA10 from '@aims/shared/shared/use-single-something-updates-a10';
import sharedSettings from '@aims/shared/sharedSettings';
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  GiftOutlined,
  HeartOutlined,
} from '@ant-design/icons';
import { Button, Card, Empty, List, Tag, Typography } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import DetailsList from '../../components/DetailsList';
import {
  createUpgPrayerAdoptionAction,
  deleteUpgPrayerAdoptionAction,
  updateUpgPrayerAdoptionAction,
} from '../../redux-store/upg-prayer-adoptions-store';
import AdoptionContactCard from '../adoptions/AdoptionContactCard';
import AdoptionPeopleGroupCard from '../adoptions/AdoptionPeopleGroupCard';
import ChangeNotesInnerModalUpgPA from './ChangeNotesInnerModalUpgPA';
import ChangeSomethingModalUpgPA from './ChangeSomethingModalUpgPA';
import ChangeStartInnerModalUpgPA from './ChangeStartInnerModalUpgPA';
import ChangeTagsInnerModalUpgPA from './ChangeTagsInnerModalUpgPA';
import DeleteUpgPrayerAdoptionModal from './DeleteUpgPrayerAdoptionModal';
import {
  extractUpgPrayerAdoption,
  upgPrayerAdoptionQuery,
  upgPrayerAdoptionUpdatesForAdminSubscription,
} from './constants';
import TagsFormItem from '../tags/TagsFormItem';

const { Title, Paragraph } = Typography;

const queryId = 'viewUpgPrayerAdoptionPage';

function ViewUpgPrayerAdoptionPage() {
  const { _id } = useParams();
  const [deleting, setDeleting] = useState();

  useSingleSomethingUpdatesA10({
    variables: { _id },
    subscription: upgPrayerAdoptionUpdatesForAdminSubscription,
    createAction: createUpgPrayerAdoptionAction,
    updateAction: updateUpgPrayerAdoptionAction,
    deleteAction: deleteUpgPrayerAdoptionAction,
    extractSomething: (resp) => resp?.data?.upgPrayerAdoptionUpdatesForAdmin,
    queryId,
    skip: !_id,
  });
  const { loading, error } = useOneM1({
    variables: { _id },
    query: upgPrayerAdoptionQuery,
    extract: extractUpgPrayerAdoption,
    fetchPolicy: 'network-only',
    updateAction: updateUpgPrayerAdoptionAction,
    queryId,
  });
  const adoption = useSingleSomethingA10('upgPrayerAdoptions', _id, queryId);

  const locale = useSelector((store) => store.locale, shallowEqual);
  const details = useMemo(() => {
    const dateFormat = new Intl.DateTimeFormat(locale, {
      dateStyle: 'medium',
    });
    if (adoption) {
      return [
        {
          title: 'Start Date',
          value: `${
            adoption.startDate
              ? dateFormat.format(new Date(adoption.startDate))
              : 'Not Specified'
          }`,
          edit: (
            <ChangeSomethingModalUpgPA queryId={queryId} adoption={adoption}>
              <ChangeStartInnerModalUpgPA />
            </ChangeSomethingModalUpgPA>
          ),
        },
        adoption.adoptionId
          ? {
              title: 'Associated UPG Sponsorship',
              component: (
                <Link to={`/adoption/view/${adoption.adoptionId}`}>
                  <GiftOutlined style={{ marginRight: 4 }} />
                  View Sponsorship
                </Link>
              ),
            }
          : undefined,
      ].filter((f) => f);
    }
    return [];
  }, [adoption, locale]);

  const history = useHistory();
  const onCancelDelete = () => setDeleting();
  const onDeleteFinish = () => history.push('/upg-prayer-adoptions');
  const handleDelete = useCallback(() => {
    setDeleting({
      upgPrayerAdoption: adoption,
      queryId,
    });
  }, [adoption]);
  return (
    <CSPage title="UPG Prayer Adoption">
      <CSPageHeader
        titleComponent={
          <Title>
            <HeartOutlined style={{ marginRight: 16 }} />
            UPG Prayer Adoption
          </Title>
        }
        backActions={[
          <Link key="back" to={'/upg-prayer-adoptions'}>
            <Button type="text" icon={<ArrowLeftOutlined />}>
              Back to UPG Prayer Adoptions
            </Button>
          </Link>,
        ]}
        topActions={[
          <Button
            key="delete"
            type="link"
            onClick={handleDelete}
            icon={<DeleteOutlined />}
          >
            Delete UPG Prayer Adoption
          </Button>,
        ].filter((b) => b)}
      />
      <Title level={3}>UPG Prayer Adoption Details</Title>
      <Card>
        <DetailsList details={details} loading={loading} />
        <div>
          {adoption && adoption.tags && (
            <div className="tags">
              {adoption.tags.map((tag) => (
                <Tag
                  key={tag}
                  color={sharedSettings.colors.primary}
                  style={{ marginTop: 10 }}
                >
                  {tag}
                </Tag>
              ))}
              <ChangeSomethingModalUpgPA
                queryId={queryId}
                adoption={adoption}
                btnText={
                  adoption && adoption.tags && adoption.tags.length > 0
                    ? undefined
                    : 'Add Tags'
                }
              >
                <ChangeTagsInnerModalUpgPA />
              </ChangeSomethingModalUpgPA>
            </div>
          )}
        </div>
      </Card>
      <div style={{ height: 32 }} />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Title level={3}>Notes</Title>
        <ChangeSomethingModalUpgPA queryId={queryId} adoption={adoption}>
          <ChangeNotesInnerModalUpgPA />
        </ChangeSomethingModalUpgPA>
      </div>
      {adoption && adoption.notes ? (
        <Card>
          {adoption.notes.split('\n').map((p, i) => (
            <Paragraph key={i}>{p.trim()}</Paragraph>
          ))}
        </Card>
      ) : (
        <Card>Nothing yet ...</Card>
      )}
      <div style={{ height: 32 }} />
      <List
        dataSource={[
          <AdoptionContactCard
            key="adopter"
            title="Adopter"
            loading={loading}
            contactDetails={adoption && adoption.contact}
            notSpecified={
              <div>
                <Paragraph>Not Specified</Paragraph>
              </div>
            }
          />,
          <AdoptionPeopleGroupCard
            key="pg"
            loading={loading}
            peopleGroup={adoption && adoption.peopleGroup}
            adoption={adoption}
          />,
        ]}
        renderItem={(item) => <List.Item>{item}</List.Item>}
        loading={loading}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Details"
            />
          ),
        }}
        grid={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2, gutter: 16 }}
      />
      <div style={{ height: 32 }} />
      <DeleteUpgPrayerAdoptionModal
        visible={deleting}
        onFinish={onDeleteFinish}
        onCancel={onCancelDelete}
      />
    </CSPage>
  );
}

export default ViewUpgPrayerAdoptionPage;
