import { peopleGroupShortAttributes } from '../people-groups/constants';
import { gql } from '@apollo/client';
import { holisticProjectShortAttributes } from '../holistic-projects/constants';
import sharedSettings from '../sharedSettings';

export const ReportTypes = {
  PG_QUARTERLY: {
    key: 'PG_QUARTERLY',
    label: 'People Group Quarterly Report',
  },
  HOLISTIC_REQ: {
    key: 'HOLISTIC_REQ',
    label: 'Holistic Request Form',
  },
  HOLISTIC_PROGRESS: {
    key: 'HOLISTIC_PROGRESS',
    label: 'Holistic Progress Report',
  },
  TRAIN_EVENT: {
    key: 'TRAIN_EVENT',
    label: 'Training Event Report',
  },
  TRAIN_QUARTERLY: {
    key: 'TRAIN_QUARTERLY',
    label: 'Quarterly Training Report',
  },
  STRATEGY_WORKSHOP_REPORT: {
    key: 'STRATEGY_WORKSHOP_REPORT',
    label: 'Strategic Workshop Report',
  },
};

export const reportFullAttributes = gql`
{
  _id
  contactId
  contact {
    _id
    fullName
  }
  quarter
  status
  peopleGroupId
  peopleGroup ${peopleGroupShortAttributes}
  regionCode
  region {
    _id
    name
    continentCode
  }
  peopleGroupIds
  peopleGroups ${peopleGroupShortAttributes}
  holisticProjectId
  holisticProject {
    projectName
    typeOfProject
  }
  reportType
  loanAmount
  publicAttachments {
    _id
    desc
    s3Key
    size
    filename
    contentType
    tags
    url
    uploaded
    processed
    thumbnail {
      s3Key
      size
      filename
      contentType
      url
      height
      width
      sizeCode
    }
    images {
      sizeCode
      url
    }
  }
  privateAttachments {
    _id
    desc
    s3Key
    size
    filename
    contentType
    tags
    url
    uploaded
    processed
    thumbnail {
      s3Key
      size
      filename
      contentType
      url
      height
      width
      sizeCode
    }
  }
  notes
  submittedAt
  createdBy
  createdByUser {
    _id
    name
  }
  isSubmitted
  isApproved
  updatedBy
  createdAt
  updatedAt
  deletedBy
  deletedAt
  submittedByUser {
    _id
    name
  }
  approval {
    status
    when
    approver {
      _id
      name
      profilePhoto {
        _id
        s3Key
        size
        filename
        contentType
        url
        thumbnail {
          url
        }
        images {
          sizeCode
          url
        }
      }
    }
  }
}
`;

export const reportShortWithDetailsAttributes = gql`
  {
    _id
    contactId
    contact {
      _id
      fullName
    }
    quarter
    status
    peopleGroupId
    peopleGroup {
      _id
      nameAcrossCountries
      country
    }
    regionCode
    region {
      name
    }
    peopleGroupIds
    holisticProjectId
    holisticProject {
      projectName
      typeOfProject
    }
    reportType
    loanAmount
    isSubmitted
    isApproved
    submittedAt
    createdBy
    createdByUser {
      _id
      name
    }
    updatedBy
    createdAt
    updatedAt
    submittedByUser {
      _id
      name
    }
    approval {
      status
      when
      approver {
        _id
        name
        profilePhoto {
          _id
          s3Key
          size
          filename
          contentType
          url
          thumbnail {
            url
          }
          images {
            sizeCode
            url
          }
        }
      }
    }
  }
`;

export const reportShortNoDetailsAttributes = gql`
  {
    _id
    contactId
    quarter
    status
    peopleGroupId
    peopleGroup {
      _id
      nameAcrossCountries
      country
    }
    regionCode
    peopleGroupIds
    holisticProjectId
    reportType
    isSubmitted
    isApproved
    submittedAt
    createdBy
    createdByUser {
      _id
      name
    }
    updatedBy
    createdAt
    updatedAt
    approval {
      status
      when
      approver {
        _id
        name
        profilePhoto {
          _id
          s3Key
          size
          filename
          contentType
          url
          thumbnail {
            url
          }
          images {
            sizeCode
            url
          }
        }
      }
    }
  }
`;

export const allReportsWithDetailsQuery = gql`
  query AllReports($first: Int, $after: String, $filters: ReportFilters) {
    allReports(first: $first, after: $after, filters: $filters) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${reportShortWithDetailsAttributes}
      }
    }
  }
`;

export const reportQuery = gql`
  query Report($_id: ID!) {
    report(_id: $_id) ${reportFullAttributes}
  }
`;

export const createReportMutation = gql`
  mutation createReport($report: ReportCreateInput!) {
    createReport(report: $report) {
      report ${reportFullAttributes}
    }
  }
`;

export const updateReportMutation = gql`
  mutation updateReport($report: ReportUpdateInput!) {
    updateReport(report: $report) {
      report ${reportFullAttributes}
    }
  }
`;

export const submitReportMutation = gql`
  mutation SubmitReport($reportId: ID!) {
    submitReport(reportId: $reportId) {
      report ${reportFullAttributes}
    }
  }
`;

export const unsubmitReportMutation = gql`
  mutation UnsubmitReport($reportId: ID!) {
    unsubmitReport(reportId: $reportId) {
      report ${reportFullAttributes}
    }
  }
`;

export const ReportApprovalStatuses = {
  APPROVED: {
    key: 'APPROVED',
    label: 'Approved',
    color: sharedSettings.colors.success,
  },
  REJECTED: {
    key: 'REJECTED',
    label: 'Rejected',
    color: sharedSettings.colors.error,
  },
  PENDING: {
    key: 'PENDING',
    label: 'Pending Approval',
    color: sharedSettings.colors.warning,
  },
  DRAFT: {
    key: 'DRAFT',
    label: 'Draft',
    color: sharedSettings.colors.red,
  },
  HOLD: {
    key: 'HOLD',
    label: 'On Hold',
    color: sharedSettings.colors.blue,
  },
};

export const TrainQuarterlyReportQuestions = {
  question1: {
    text: () =>
      'Please list all Intro to Strategic Missions (Additional AIMS) seminars taught:',
    getAnswer: (report) => 'TODO: This is BROKEN!!!!',
    // report.reportDetails.events
    //   ? report.reportDetails.events.map((e) => {
    //       const locale = useSelector((store) => store.locale, shallowEqual);
    //       console.log("original date: ", date);

    //       let date = new Date(e.date);
    //       console.log("date: ", date);
    //       let formatted = new Intl.DateTimeFormat(locale, {
    //         dateStyle: "full",
    //       }).format(date);
    //       `Date: ${formatted} Location: ${e.city}, ${e.country} Hosting Church: ${e.hostingChurch} Partnerships Established: ${e.numPartnerships}`;
    //     })
    //   : "",
  },
  question2: {
    text: () => 'Partnerships Established',
    getAnswer: (report) => report.reportDetails.numPartnerships,
  },
  question3: {
    text: () => 'Were any new UPGs adopted?',
    getAnswer: (report) =>
      report.reportDetails.adoptedUPGs
        ? report.reportDetails.adoptedUPGs.map(
            (pg) =>
              `UPG: ${pg.peopleGroupId} Lead Contact: ${pg.leadContact} # of local Churches that adopted this UPG: ${pg.numAdoptiveChurches}`,
          )
        : '',
  },
  question4: {
    text: () => 'Please list any new AIMS partner missionaries sent',
    getAnswer: (report) =>
      report.reportDetails.missionaries != null
        ? report.reportDetails.missionaries.map(
            (m) => `Name: ${m.name} Email: ${m.email} UPG: ${m.peopleGroupId}`,
          )
        : '',
  },
  publicFiles: {
    text: () =>
      "Any photos or documents you'd like to share with your supporters",
    getAnswer: (report) => (report ? report.publicAttachments : []),
  },
  privateFiles: {
    text: () => "Any photos or documents you'd like to share AIMS only",
    extra: () => 'These files will only be shared with AIMS staff',
    getAnswer: (report) => (report ? report.privateAttachments : []),
  },
  notes: {
    text: () =>
      'Notify us of any other activity, or requests for Humanitarian opportunities and/or Available Ministry opportunities',
    getAnswer: (report) => report.notes,
  },
};
