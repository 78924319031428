import { Button, message, notification } from 'antd';
import { ExportToCsv } from 'export-to-csv';
import { displayMoney, getNumberValue } from '@aims/shared/shared/utils';
import React, { useCallback, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { ExportOutlined } from '@ant-design/icons';
import { AccountTypesJ25 } from '../constants';

export function exportBalancesToCsv(transfers, locale, title) {
  const data = transfers.map((record) => {
    return {
      _id: record._id,
      name: record.name,
      fundId: record.fundId,
      fundName: record.fund?.name,
      accountType: AccountTypesJ25[record.type]?.label,
      balance: displayMoney(getNumberValue(record.balance?.balance) / 10000),
      createdAt: new Intl.DateTimeFormat(locale, {
        dateStyle: 'medium',
      }).format(new Date(record.createdAt)),
      updatedAt: new Intl.DateTimeFormat(locale, {
        dateStyle: 'medium',
      }).format(new Date(record.updatedAt)),
    };
  });
  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    title,
    useTextFile: false,
    useBom: true,
    filename: title,
    headers: [
      'Account ID',
      'Account Name',
      'Fund ID',
      'Fund Name',
      'Account Type',
      'Balance',
      'Created',
      'Updated',
    ],
  };

  const csvExporter = new ExportToCsv(options);

  csvExporter.generateCsv(data);
}

function ExportBalancesBtn({ accounts }) {
  const [exporting, setExporting] = useState(false);
  const locale = useSelector((store) => store.locale, shallowEqual);

  const handleExportCsv = useCallback(() => {
    message.info('Exporting ...');
    setExporting(true);
    try {
      const title = `Balances ${new Intl.DateTimeFormat(locale, {
        dateStyle: 'medium',
      }).format(new Date())}`.replace(',', ' ');
      exportBalancesToCsv(accounts, locale, title);
      message.success('Success');
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Error',
        description: 'We apologize.  An error occurred',
      });
    }
    setExporting(false);
  }, [locale, accounts]);
  return (
    <Button
      icon={<ExportOutlined />}
      onClick={handleExportCsv}
      loading={exporting}
      shape="round"
    >
      Export CSV
    </Button>
  );
}

export default ExportBalancesBtn;
