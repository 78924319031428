import useManyRemoteM1 from '@aims/shared/shared/use-many-remote-m1';
import { gql } from '@apollo/client';
import { Button, Typography } from 'antd';
import React, { useRef, useState } from 'react';
import settings from '../../../settings';
import FinanceReportsTable from './FinanceReportsTable';
import { RedoOutlined } from '@ant-design/icons';

const { Paragraph } = Typography;

const queryId = 'financeReportsTab';

const queryAttributes = gql`
  {
    _id
    type
    desc
    fileId
    file {
      _id
      desc
      s3Key
      size
      filename
      contentType
      url
      uploaded
    }
    total
    totalIn
    totalOut
    totalX
    createdAt
    updatedAt
  }
`;

const query = gql`
  query AllFinanceReportsForAdmin($first: Int, $after: String, $filters: FinanceReportFiltersForAdmin, $sortBy: [SortBy]) {
    allFinanceReportsForAdmin(first: $first, after: $after, filters: $filters, sortBy: $sortBy) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node ${queryAttributes}
      }
    }
  }
`;

const extract = (data) => data.allFinanceReportsForAdmin;

function FinanceReportsTab({ filters: parentFilters }) {
  const [sortedBy, setSortedBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState('DESC');
  const sortBy = useRef([
    { key: sortedBy, order: sortOrder },
    { key: '_score', order: 'DESC' },
  ]);

  const filters = useRef({
    ...parentFilters,
  });

  const {
    error,
    loading,
    data: financeReports,
    search: financeReportSearch,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemoteM1({
    query: query,
    extract: extract,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
  });

  return (
    <>
      <Paragraph style={{ textAlign: 'center', marginBottom: 12 }}>
        These reports are automatically generated at midnight on the first of
        every month.
      </Paragraph>
      <div style={{ display: 'flex', marginBottom: 12 }}>
        <div style={{ flex: 1 }} />
        <Button onClick={refetch} icon={<RedoOutlined />}>
          Refresh
        </Button>
      </div>
      <FinanceReportsTable financeReports={financeReports} loading={loading} />
      <div>{hasNextPage && <Button onClick={next}>Load More</Button>}</div>
    </>
  );
}

export default FinanceReportsTab;
