import useOneM1 from '@aims/shared/shared/use-one-m1';
import useSingleSomethingA10 from '@aims/shared/shared/use-single-something-a10';
import useSingleSomethingUpdatesA10 from '@aims/shared/shared/use-single-something-updates-a10';
import { displayMoney } from '@aims/shared/shared/utils';
import sharedSettings from '@aims/shared/sharedSettings';
import {
  ArrowLeftOutlined,
  BankOutlined,
  DeleteOutlined,
  DollarOutlined,
  MailOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Card, Empty, List, Tag, Typography } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import CSPage from '../../components/CSPage';
import CSPageHeader from '../../components/CSPageHeader';
import DetailsList from '../../components/DetailsList';
import {
  createAdoptionAction,
  deleteAdoptionAction,
  updateAdoptionAction,
} from '../../redux-store/adoptions-store';
import useQueryParams from '../../shared/use-query-params';
import AdoptionContactCard from './AdoptionContactCard';
import AdoptionPeopleGroupCard from './AdoptionPeopleGroupCard';
import DeleteAdoptionModal from './DeleteAdoptionModal';
import TriggerNewAdoptionEmailCampaignModal from './TriggerNewAdoptionEmailCampaignModal';
import AddFWRCInnerModalA10 from './a10-adoptions/AddFWRCInnerModalA10';
import ChangeMontlyAmountInnerModalA10 from './a10-adoptions/ChangeMontlyAmountInnerModalA10';
import ChangeNotesInnerModalA10 from './a10-adoptions/ChangeNotesInnerModalA10';
import ChangeSomethingModalA10 from './a10-adoptions/ChangeSomethingModalA10';
import ChangeStartEndDateInnerModalA10 from './a10-adoptions/ChangeStartEndDateInnerModalA10';
import ChangeStatusInnerModalA10 from './a10-adoptions/ChangeStatusInnerModalA10';
import ChangeTagsInnerModalA10 from './a10-adoptions/ChangeTagsInnerModalA10';
import {
  AdoptionLevels,
  AdoptionStatuses,
  adoptionForAdminQuery,
  extractAdoptionForAdmin,
  singleAdoptionUpdatesSubscription,
} from './constants';
import { getUnfundedSince } from './utils';
import AnyHistory from '../../components/AnyHistory';
import DeleteThingDropdown from '../../components/DeleteThingDropdown';
import AllocateFromPoolModal from './AllocateFromPoolModal';

const { Title, Text, Paragraph } = Typography;

const PageLinks = {
  '@contact': {
    page: (_id) => `/contact/view/${_id}/dashboard`,
    label: 'View Contact',
  },
  '@people-group': {
    page: (_id) => `/people-group/view/${_id}/info`,
    label: 'View People Group',
  },
  '@h-project': {
    page: (_id) => `/holistic-project/view/${_id}/details`,
    label: 'View Holistic Project',
  },
  '@pp-payment': {
    page: (_id) => `/pushpay/donations?_id=${_id}`,
    label: 'View Donation',
  },
  '@account': {
    page: (_id) => `/accounting/accounts/view/${_id}`,
    label: 'View Account',
  },
  '@p-sponsorship': {
    page: (_id) => `/project-sponsorship/view/${_id}`,
    label: 'View Project Sponsorship',
  },
  '@pp-recurring-payment': {
    page: (_id) => `/pushpay/recurring?_id=${_id}`,
    label: 'View Recurring Payment',
  },
};

export function AdoptionPayoutHistory({ payoutHistory }) {
  const locale = useSelector((store) => store.locale, shallowEqual);
  return (
    <Card>
      <List
        dataSource={payoutHistory}
        renderItem={(item) => {
          const completedOn = new Intl.DateTimeFormat(locale, {
            dateStyle: 'medium',
          }).format(new Date(item.completedOn));
          const quarterArr = item.quarter.split('.');

          const payoutDesc = 'Payout for '.concat(
            quarterArr[1],
            ' ',
            quarterArr[0],
            ' completed on ',
            completedOn,
          );
          return <Paragraph>{payoutDesc}</Paragraph>;
        }}
      />
    </Card>
  );
}

function AdoptionHistoryV2({ adoptionId }) {
  const historyFilterFunc = useCallback(
    (a) => {
      let ret = true;
      if (a.adoptionId !== adoptionId) {
        ret = false;
      }
      return ret;
    },
    [adoptionId],
  );
  return (
    <AnyHistory
      filterFunc={historyFilterFunc}
      filters={{ adoptionId }}
      title="Change History"
    />
  );
}

export function AdoptionHistory({ adoptionHistory }) {
  const locale = useSelector((store) => store.locale, shallowEqual);
  const items = useMemo(() => {
    if (adoptionHistory) {
      return adoptionHistory
        .slice()
        .sort((a, b) => b?.createdAt?.localeCompare(a?.createdAt));
    }
    return [];
  }, [adoptionHistory]);
  return (
    <Card>
      <List
        dataSource={items}
        renderItem={(item) => {
          const results = item.desc.match(
            /@(contact|people-group|h-project|pp-payment|account|p-sponsorship|pp-recurring-payment):([A-Z0-9]{32}|[0-9]{1,6}:[A-Z]{1,3}|[a-zA-Z0-9_-]{22})/g,
          );
          const parts = [];
          let start = 0;
          if (results) {
            results.forEach((result) => {
              const index = item.desc.slice(start).indexOf(result);
              const first = item.desc.slice(start, start + index);
              if (first) {
                parts.push(first);
                start += first.length;
              }
              const linkParts = result.split(':');
              const page = PageLinks[linkParts[0]];
              if (page) {
                parts.push(
                  <Link to={page.page(linkParts.slice(1).join(':'))}>
                    {page.label}
                  </Link>,
                );
              }
              start += result.length;
            });
            parts.push(item.desc.slice(start));
          } else {
            parts.push(item.desc);
          }
          return (
            <List.Item>
              <Paragraph>
                <Text style={{ color: sharedSettings.colors.textGray }}>
                  {new Intl.DateTimeFormat(locale, {
                    dateStyle: 'medium',
                    timeStyle: 'short',
                  }).format(new Date(item.createdAt))}
                </Text>
                <br />
                {parts.map((p, i) => (
                  <Text key={i}>{p}</Text>
                ))}
              </Paragraph>
            </List.Item>
          );
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No History"
            />
          ),
        }}
        grid={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1, gutter: 16 }}
      />
    </Card>
  );
}

function ViewAdoption() {
  const history = useHistory();
  const { _id } = useParams();
  const query = useQueryParams();

  const handleBack = useCallback(() => {
    if (query && query.from) {
      history.push(query.from);
    } else {
      history.push('/adoptions');
    }
  }, [query, history]);

  const queryId = 'view-adoption';
  useSingleSomethingUpdatesA10({
    variables: { _id },
    subscription: singleAdoptionUpdatesSubscription,
    createAction: createAdoptionAction,
    updateAction: updateAdoptionAction,
    deleteAction: deleteAdoptionAction,
    extractSomething: (resp) => resp?.data?.adoptionUpdatesForAdmin,
    queryId,
    skip: !_id,
  });
  const { loading, error } = useOneM1({
    variables: { _id },
    query: adoptionForAdminQuery,
    extract: extractAdoptionForAdmin,
    fetchPolicy: 'network-only',
    updateAction: updateAdoptionAction,
    queryId,
  });
  const adoption = useSingleSomethingA10('adoptions', _id, queryId);
  const [triggeringEmailCampaign, setTriggeringEmailCampaign] = useState();
  const [deleting, setDeleting] = useState();
  const handleDelete = useCallback(async () => {
    setDeleting({
      adoption,
      queryId,
    });
  }, [queryId, adoption]);
  const [allocating, setAllocating] = useState();
  const onAllocate = () => setAllocating({ adoption });
  const onCancelAllocate = () => setAllocating(undefined);
  const onFinishAllocate = () => setAllocating(undefined);

  const locale = useSelector((store) => store.locale, shallowEqual);
  const details = useMemo(() => {
    const dateFormat = new Intl.DateTimeFormat(locale, {
      dateStyle: 'medium',
    });
    if (adoption) {
      const status = adoption.status && AdoptionStatuses[adoption.status];
      const level =
        AdoptionLevels[adoption.level] &&
        `${AdoptionLevels[adoption.level].label} @ ${displayMoney(
          adoption.amount / 10000,
        )} / mo`;
      let payoutColor;
      if (adoption.numOfPayoutQuarters < 3) {
        payoutColor = sharedSettings.colors.green;
      } else if (adoption.numOfPayoutQuarters < 4) {
        payoutColor = sharedSettings.colors.yellow;
      } else {
        payoutColor = sharedSettings.colors.red;
      }
      const unfundedSince = getUnfundedSince(adoption);
      return [
        {
          title: 'Status',
          component: (
            <div style={{ paddingTop: 4 }}>
              <Tag color={status.color}>{status.label}</Tag>
            </div>
          ),
          edit: (
            <ChangeSomethingModalA10 adoption={adoption}>
              <ChangeStatusInnerModalA10 />
            </ChangeSomethingModalA10>
          ),
        },
        {
          title: 'Level',
          value: level,
          edit: (
            <ChangeSomethingModalA10 adoption={adoption}>
              <ChangeMontlyAmountInnerModalA10 />
            </ChangeSomethingModalA10>
          ),
        },
        {
          title: 'Start/End Date',
          value: `${
            adoption.startDate
              ? dateFormat.format(new Date(adoption.startDate))
              : 'Not Specified'
          } / ${
            adoption.endDate
              ? dateFormat.format(new Date(adoption.endDate))
              : 'Not Specified'
          }`,
          edit: (
            <ChangeSomethingModalA10 adoption={adoption}>
              <ChangeStartEndDateInnerModalA10 />
            </ChangeSomethingModalA10>
          ),
        },
        {
          title: 'First/Last Payment',
          value: `${
            adoption.firstPaymentDate
              ? dateFormat.format(new Date(adoption.firstPaymentDate))
              : 'Not Specified'
          } / ${
            adoption.lastPaymentDate
              ? dateFormat.format(new Date(adoption.lastPaymentDate))
              : 'Not Specified'
          }`,
        },
        {
          title: '# of Active Recurring Donations',
          value: adoption.recurringPaymentIds
            ? adoption.recurringPaymentIds.length
            : 0,
        },
        unfundedSince
          ? {
              title: 'Unfunded',
              value: unfundedSince,
            }
          : undefined,
        {
          title: 'Completed Payouts',
          color: payoutColor,
          component: (
            <div>
              <Text style={{ color: payoutColor }}>
                {adoption.numOfPayoutQuarters}
              </Text>
            </div>
          ),
        },
        adoption.status !== AdoptionStatuses.UNFUNDED.key && {
          title: 'Fulfilled through Online Catalog',
          value: adoption.isOnline ? 'Yes' : 'No',
        },
        adoption.isOnline
          ? {
              title: 'Checked Commit to Adopt',
              value: adoption.commitToAdopt ? 'Yes' : 'No',
            }
          : undefined,
        {
          title: 'Reports',
          component: (
            <Link to={`/people-group/view/${adoption.peopleGroupId}/reports`}>
              <Text
                style={{
                  color: sharedSettings.colors.primary,
                  cursor: 'pointer',
                }}
              >
                View Reports
              </Text>
            </Link>
          ),
        },
        adoption.accountId
          ? {
              title: 'Account',
              component: (
                <Link to={`/accounting/accounts/view/${adoption.accountId}`}>
                  <BankOutlined style={{ marginRight: 4 }} />
                  View Account
                </Link>
              ),
            }
          : undefined,
      ].filter((f) => f);
    }
    return [];
  }, [adoption, locale]);

  return (
    <CSPage title="UPG Sponsorship">
      <CSPageHeader
        titleComponent={
          <div style={{ display: 'flex' }}>
            <Title
              className="cs-header-title"
              style={{ marginBottom: 8, marginTop: 0 }}
            >
              UPG Sponsorship
            </Title>
            <div style={{ flex: 1 }} />
            <DeleteThingDropdown
              thing={adoption}
              label="Delete Adoption"
              handleDelete={handleDelete}
              moreItems={[]}
              moreCases={(key) => {}}
            />
          </div>
        }
        backActions={[
          <Button
            key="back"
            type="text"
            onClick={handleBack}
            icon={<ArrowLeftOutlined />}
          >
            Back to UPG Sponsorships
          </Button>,
        ]}
        topActions={[
          adoption && !adoption.newAdoptionCampaignTriggeredAt && (
            <Button
              key="trigger"
              type="link"
              onClick={() =>
                setTriggeringEmailCampaign({
                  adoption,
                  queryId,
                })
              }
              icon={<MailOutlined />}
            >
              Launch Email Campaign
            </Button>
          ),
          <Button
            key="pool"
            type="link"
            onClick={onAllocate}
            icon={<DollarOutlined />}
          >
            Allocate from Sponsorship Pool
          </Button>,
          // <Button
          //   key="delete"
          //   type="link"
          //   onClick={handleDelete}
          //   icon={<DeleteOutlined />}
          // >
          //   Delete Sponsorship
          // </Button>,
        ].filter((b) => b)}
      />
      <Title level={3}>Sponsorship Details</Title>
      <Card>
        <DetailsList details={details} loading={loading} />
        <div>
          {adoption && adoption.tags && (
            <div className="tags">
              {adoption.tags.map((tag) => (
                <Tag
                  key={tag.key}
                  color={sharedSettings.colors.primary}
                  style={{ marginTop: 10 }}
                >
                  {tag.key}
                </Tag>
              ))}
              <ChangeSomethingModalA10
                adoption={adoption}
                btnText={
                  adoption && adoption.tags && adoption.tags.length > 0
                    ? undefined
                    : 'Add Tags'
                }
              >
                <ChangeTagsInnerModalA10 />
              </ChangeSomethingModalA10>
            </div>
          )}
        </div>
      </Card>
      <div style={{ height: 32 }} />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Title level={3}>Notes</Title>
        <ChangeSomethingModalA10 adoption={adoption}>
          <ChangeNotesInnerModalA10 />
        </ChangeSomethingModalA10>
      </div>
      {adoption && adoption.notes ? (
        <Card>
          {adoption.notes.split('\n').map((p, i) => (
            <Paragraph key={i}>{p.trim()}</Paragraph>
          ))}
        </Card>
      ) : (
        <Card>Nothing yet ...</Card>
      )}
      <div style={{ height: 32 }} />
      <List
        dataSource={[
          <AdoptionPeopleGroupCard
            key="pg"
            loading={loading}
            peopleGroup={adoption && adoption.peopleGroup}
            adoption={adoption}
          />,
          <AdoptionContactCard
            key="fw"
            title="Field Worker"
            loading={loading}
            contactDetails={adoption && adoption.fieldWorker}
            notSpecified={
              <div>
                <Paragraph>Not Specified</Paragraph>
                <ChangeSomethingModalA10
                  adoption={adoption}
                  btnProps={{ type: 'dashed', icon: <PlusOutlined /> }}
                  btnText="Add Field Worker"
                >
                  <AddFWRCInnerModalA10
                    filters={{
                      peopleGroupId: adoption?.peopleGroupId,
                      isRegionalCoord: false,
                    }}
                  />
                </ChangeSomethingModalA10>
              </div>
            }
          />,
          <AdoptionContactCard
            key="rc"
            title="Regional Coordinator"
            loading={loading}
            contactDetails={adoption && adoption.regionalCoord}
            notSpecified={
              <div>
                <Paragraph>Not Specified</Paragraph>
                <ChangeSomethingModalA10
                  adoption={adoption}
                  btnProps={{ type: 'dashed', icon: <PlusOutlined /> }}
                  btnText="Add Regional Coordinator"
                >
                  <AddFWRCInnerModalA10
                    filters={{
                      peopleGroupId: adoption?.peopleGroupId,
                      isFieldWorker: true,
                    }}
                  />
                </ChangeSomethingModalA10>
              </div>
            }
          />,
          ...(adoption && adoption.sponsors && adoption.sponsors.length > 0
            ? adoption.sponsors.map((sponsor) => (
                <AdoptionContactCard
                  key={sponsor._id}
                  title="Sponsor"
                  loading={loading}
                  contactDetails={sponsor}
                />
              ))
            : [
                <div key="ns">
                  <Title level={3}>No Sponsors</Title>
                  <Card
                    styles={{ body: { display: 'flex' } }}
                    loading={loading}
                  >
                    {!loading && (
                      <div>
                        Add sponsors by associating payments with this
                        sponsorship.
                      </div>
                    )}
                  </Card>
                </div>,
              ]),
        ]}
        renderItem={(item) => <List.Item>{item}</List.Item>}
        loading={loading}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Details"
            />
          ),
        }}
        grid={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2, gutter: 16 }}
      />
      <div style={{ height: 32 }} />
      <Title level={3}>Donation History</Title>
      {adoption &&
      adoption.paymentHistory &&
      adoption.paymentHistory.length > 0 ? (
        <AdoptionHistory adoptionHistory={adoption.paymentHistory} />
      ) : (
        <Card>No Donation History</Card>
      )}
      <div style={{ height: 32 }} />
      <Title level={3}>Payout History</Title>
      {adoption && adoption.payoutHistory && (
        <AdoptionPayoutHistory payoutHistory={adoption.payoutHistory} />
      )}
      {adoption && !adoption.payoutHistory && <Card>No Payout History</Card>}
      <div style={{ height: 32 }} />
      <AdoptionHistoryV2 adoptionId={_id} />
      {adoption && !adoption.history && <Card>No History</Card>}
      <DeleteAdoptionModal deleting={deleting} setDeleting={setDeleting} />
      <TriggerNewAdoptionEmailCampaignModal
        visible={triggeringEmailCampaign}
        onFinish={() => setTriggeringEmailCampaign(undefined)}
        onCancel={() => setTriggeringEmailCampaign(undefined)}
      />
      <AllocateFromPoolModal
        visible={allocating}
        onCancel={onCancelAllocate}
        onFinish={onFinishAllocate}
      />
    </CSPage>
  );
}

export default ViewAdoption;
